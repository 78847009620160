import React from "react";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import MuiPhoneNumber from "material-ui-phone-number";
import { connect } from "react-redux";
import { sendSmsLink } from "../../Actions";
import { defaultRequestForm, formTypes } from "../../Utils/constants";
import "./style.scss";
import { clientThemeColor, partnerThemeColor } from "../../Util";

interface IProps {
  sendSmsLink: (data: any, callback: any) => void;
  isClient?: boolean;
  imgPath: string;
  title: string;
  description: string;
  iosPath?: string;
  androidPath?: string;
}
function AppConnections(props: IProps) {
  const {
    isClient = false,
    imgPath,
    title,
    description,
    iosPath,
    androidPath,
  } = props;
  const [phoneNumber, setPhoneNumber] = React.useState<string>("");
  const [loader, setLoader] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const successMessage =
    "Thanks for submitting your request, you should receive the download link shortly to the given mobile number.";
  const handleClick = (osType) => {
    if (osType === "ios") {
      if (iosPath.includes("http")) {
        window.open(iosPath);
      } else {
        setMessage(iosPath);
        setOpen(true);
      }
    } else {
      if (androidPath.includes("http")) {
        window.open(androidPath);
      } else {
        setMessage(androidPath);
        setOpen(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const open = Boolean(anchorEl);
  // const id = open ? "simple-popover" : undefined;

  const handleForm = () => {
    const data = defaultRequestForm;
    data.form = props.isClient
      ? formTypes.clientSmsLink
      : formTypes.partnerSmsLink;
    const removeZeroFromNumber =
      phoneNumber.charAt(0) === "0" ? phoneNumber.slice(1) : phoneNumber;
    data.phone = "+44" + removeZeroFromNumber;
    setLoader(true);
    props.sendSmsLink(data, (res) => {
      // if (res && !res.fail) {
        setMessage(successMessage);
        setTimeout(() => {
          setMessage("");
        }, 10 * 1000); // 10 secs
      // }
      setLoader(false);
    });
  };
  return (
    <section className="cont-mb cl-section section-block app-connection">
      <div
        className="app-connection-content"
        style={{
          background: isClient ? "rgba(13, 119, 30, 0.1)" : "#e5f2ff",
          position: "relative",
        }}
      >
        <img alt={"reach-partners"} className="partner-img" src={imgPath} />
        <div className="right-cont">
          <p className="title">{title}</p>
          <p className="subtitle">{description}</p>
          <p
            style={{
              fontSize: 14,
              opacity: 0.8,
              marginBottom: 5,
            }}
          >
            Get the app download link directly via SMS to your phone
          </p>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              // gap: 10,
              marginBottom: 20,
              height: 45,
              // flexWrap: "wrap",
            }}
          >
            <MuiPhoneNumber
              defaultCountry={"gb"}
              className={"sms-link-country-flag"}
              onChange={(val) => {}}
              value={""}
              onlyCountries={["gb"]}
              placeholder={"phno"}
              countryCodeEditable={false}
              style={{ color: "red" }}
              disabled={true}
            />
            <input
              placeholder="Enter your mobile number"
              type="number"
              className="sms-link-input"
              value={phoneNumber}
              onChange={(e) => {
                const { value } = e.target;
                if (value.charAt(0) === "0" && value.length <= 11) {
                  setPhoneNumber(value);
                } else if (value.length <= 10) {
                  setPhoneNumber(value);
                }
              }}
            />
            <Button
              className=""
              variant="contained"
              color="primary"
              style={{
                backgroundColor: isClient ? clientThemeColor: partnerThemeColor,
                borderRadius: "4px",
                opacity: phoneNumber.length < 10 ? 0.6 : 1,
                color: "white",
              }}
              onClick={() => {
                handleForm();
              }}
              disabled={phoneNumber.length < 10}
            >
              {loader ? (
                <CircularProgress size={18} style={{ color: "white" }} />
              ) : (
                "Get it"
              )}
            </Button>
          </div>
          <div
            style={{
              fontSize: 16,
              padding: "1px 1px",
              borderRadius: 4,
            }}
          >
            {message}
          </div>
          {/* <p className="subtitle">Download our app</p> */}
          {/* <p>
            App will be available to download from 15th July 2022, please check
            back!
          </p> */}
          {/* <div className="apps-store">
            <img
              alt="reach-app"
              src="./media/app-store.png"
              style={{ width: "48%" }}
              onClick={() => handleClick("ios")}
            />
            <span style={{ width: "4%", display: "inline-block" }} />
            <img
              alt="reach-app"
              src="./media/play-store.webp"
              style={{ width: "48%" }}
              onClick={() => handleClick("android")}
            />
          </div> */}
        </div>
        <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
          <div
            style={{
              backgroundColor: isClient ? clientThemeColor:partnerThemeColor,
              color: "white",
              fontSize: 18,
              padding: "10px 15px",
              borderRadius: 4,
            }}
          >
            {message}
          </div>
        </Snackbar>
      </div>
    </section>
  );
}
const mapStateToProps = (store: any) => {
  return {
    // home: store.home,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    sendSmsLink: (data, callback) => dispatch(sendSmsLink(data, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AppConnections);
