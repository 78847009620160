import React, { useEffect } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import ClientHome from "./ClientHome";
import { connect } from "react-redux";
import { firebaseGuestLogin, getFirebaseUserInfo } from "../Actions";
import firebase from "../Firebase";
import { CircularProgress } from "@material-ui/core";
function Client(props) {
  let { path } = useRouteMatch();
  const initialAuthTriggered = React.useRef(false) // using this flag so that we are not triggering the guest call twice

  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        console.log("initialAuthTriggered",initialAuthTriggered.current)
        if(!initialAuthTriggered.current){
          checkauth();
          initialAuthTriggered.current = true
        }

      });
    return () => unregisterAuthObserver();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      const elem = document.getElementById("hubspot-messages-iframe-container");
      if (elem) {
        elem.style.visibility = "hidden";
      }
    }, 1000);
    return () => {
      const elem = document.getElementById("hubspot-messages-iframe-container");
      if (elem) {
        elem.style.visibility = "visible";
      }
    };
  }, []);
  function checkauth() {
    if (localStorage.getItem("sessionDetail")) {
      //adding 500ms because, firebase cookie is not ready initially when use opening this route directly
      // setTimeout(() => {
      props.getFirebaseUserInfo();
      // }, 500);
    } else {
      firebaseGuestLogin();
    }
  }
  if (!props.home.isLoggedIn) {
    return (
      <div
        style={{
          height: "90vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress style={{ color: "green" }} />
      </div>
    );
  }
  return (
    <div id="client-container">
      <Switch>
        <Route exact path={`${path}`}>
          <ClientHome />
        </Route>
      </Switch>
    </div>
  );
}
const mapStateToProps = (store: any) => {
  return {
    home: store.home,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getFirebaseUserInfo: () => dispatch(getFirebaseUserInfo()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Client);
