import PickUpBusinessContainer from "./PickupBusiness";
import SignupCatalog from "../../+Home/Components/SignupCatalog/SignupCatalog";
import Banner from "../../Components/Banner";
import "./style.scss";

export default function IndustryComponent({ industryData, features }) {
  const list1 = features.slice(0, 5);
  const list2 = features.slice(5);
  const { banner = {} } = industryData || {};
  const { image = "", title, brief = "" } = banner;

  const bannerData = {
    image,
    name: title,
    brief,
  };
  return (
    <div className="industry-container">
      <Banner data={bannerData} />
      <div className="cl-section">
        {list1.map((item, idx) => (
          <PickUpBusinessContainer
            key={idx}
            data={item}
            extraClass={`pickup-component${idx}`}
            industryId={industryData.id || ""}
          />
        ))}
      </div>
      <SignupCatalog />
      <div className="cl-section ">
        {list2.map((item, idx) => (
          <PickUpBusinessContainer
            key={idx}
            data={item}
            extraClass={`pickup-component${idx}`}
            industryId={industryData.id || ""}
          />
        ))}
      </div>
    </div>
  );
}
