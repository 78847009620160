import React, { useEffect, useState } from "react";
import "./App.scss";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";
import Home from "./+Home";
import Feature from "./+Features";
import FeatureInfo from "./+FeatureInfo";
import TermsOfService from "./+TermsOfService";
import Pricing from "./+Pricing";
import Industry from "./+Industry";
import ContactUs from "./+ContactUs";
import PolicyPolicy from "./+PrivacyPolicy";
import { ThemeProvider } from "@material-ui/core";
import { createMuiTheme } from "@material-ui/core/styles";
import Client from "./+Client";
import Header from "./Components/Header";
import { ScrollToTop } from "react-router-scroll-to-top";
import AppContext, { AppContextData } from "./Context";
import BottomBar from "./+Home/Components/BottomBar/BottomBar";
import IndustryData from "./Data/IndustryData.json";
import Popup from "./Components/Popup";
import { Provider } from "react-redux";
import { store } from "./Store";
import Footer from "./Components/Footer";
import ButtonCta from "./Components/ButtonCta";
import PACKAGEJSON from "../package.json";
declare global {
  interface Window {
    someMethod: () => void;
  }
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#007fff",
    },
  },
});
function Layout(props) {
  return (
    <div>
      <Header />
      {props.children}
      <Footer />
      <BottomBar />
      <Popup />
    </div>
  );
}
function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Client />
      </Route>
      <Route exact path="/business">
        <Home />
      </Route>
      <Route path="/en-gb/terms">
        <TermsOfService />
      </Route>
      <Route path="/en-gb/privacy">
        <PolicyPolicy />
      </Route>
      <Route path="/marketplace">
        <Client />
      </Route>
      <Route path="/:countrycode/industries/:id">
        <Industry />
      </Route>
      <Route exact path="/:countrycode/features">
        <Feature />
      </Route>
      <Route path="/:countrycode/features/:id">
        <FeatureInfo />
      </Route>
      <Route path="/:countrycode/pricing">
        <Pricing />
      </Route>
      <Route path="/:countrycode/contact-us">
        <ContactUs />
      </Route>
      <Route path="*">
        <NoRoute />
      </Route>
    </Switch>
  );
}
function App() {
  const [contextData, setContextData] = useState({
    ...AppContextData,
    updateData: (data) => setContextData({ ...contextData, data: data }),
  });
  useEffect(() => {
    const { pathname } = window.location;
    if (pathname.includes("/industries/")) {
      const isIndustryValid = IndustryData.find((d) =>
        pathname.includes(d.url)
      );
      if (isIndustryValid) {
        localStorage.setItem("personalizedIndustry", pathname);
      } else {
        localStorage.setItem("personalizedIndustry", IndustryData[0].url);
      }
    } else if (!localStorage.getItem("personalizedIndustry")) {
      localStorage.setItem("personalizedIndustry", IndustryData[0].url);
    }
    console.log(
      `----------------------- VERSION ${PACKAGEJSON.version} ----------------------------`
    );
  }, []);
  return (
    <div>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <AppContext.Provider value={contextData}>
            <BrowserRouter>
              <Layout>
                <ScrollToTop>
                  <Routes />
                </ScrollToTop>
              </Layout>
            </BrowserRouter>
          </AppContext.Provider>
        </ThemeProvider>
      </Provider>
    </div>
  );
}
function NoRoute() {
  const history = useHistory();
  return (
    <div
      style={{
        height: "calc(100vh - 75px - 190px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <p style={{ fontSize: 30, marginBottom: 20 }}>Page not found</p>
      <ButtonCta
        label={"Go to home page"}
        onClick={() => {
          history.push("/marketplace");
        }}
      />
    </div>
  );
}
export default App;
