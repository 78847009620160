import IndustryComponent from "./Components";
import IndustryData from "../Data/IndustryData.json";
import Features from "../Data/Features.json";

import { useEffect, useState } from "react";
export default function IndustryContainer() {
  const [industry, setIndustry] = useState<any>({});
  const [features, setFeatures] = useState<any>([]);
  useEffect(() => {
    const industry = IndustryData.find(
      (d) => d.url === window.location.pathname
    );
    if (industry) {
      setIndustry(industry);
      const features: any[] = Features.reduce((acc, d) => {
        const feature = industry.Features.find((item) => item.id === d.id);
        if (feature) {
          if (feature.image) {
            d.image = feature.image;
          }
          return [...acc, d];
        }

        return acc;
      }, []);
      setFeatures(features);
    }
  }, []);

  return <IndustryComponent industryData={industry} features={features} />;
}
