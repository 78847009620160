import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
interface recaptchaProps {
  hanldeRecaptcha: Function;
}
const Recaptcha: React.FC<recaptchaProps> = (props) => {
  return (
    <ReCAPTCHA
      sitekey="6LeK5n4aAAAAAGIdui9Aqt9SR1fJ69H9DkjQv5ZD"
      onChange={(d) => props.hanldeRecaptcha(true)}
      onExpired={() => props.hanldeRecaptcha(false)}
    />
  );
};
export default Recaptcha;
