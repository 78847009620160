import React, { useState } from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import ButtonUI from "../../UIElements/ButtonUI";
import Recaptcha from "./Recaptcha";
import TextInputUI from "../../UIElements/TextInputUI";

const defaultValues = {
  name: "",
  email: "",
  businessEmail: "",
  businessName: "",
  businessContactName: "",
  postCode: "",
  phone: "",
};
interface IProps {
  action: (data: any) => void;
  errorText: string;
  isLoading: boolean;
}
const RequestDemo = (props: IProps) => {
  const [fieldValues, setFieldValues] = useState(defaultValues);
  const [verifyRecaptcha, setVerifyRecaptcha] = useState(false);

  const validate = () => {
    const validateFlag =
      fieldValues.name !== "" &&
      validateEmail(fieldValues.email) &&
      fieldValues.businessName !== "" &&
      fieldValues.businessContactName !== "" &&
      validateEmail(fieldValues.businessEmail) &&
      fieldValues.phone.length >= 15 &&
      verifyRecaptcha;
    return validateFlag;
  };

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const submitForm = () => {
    const data = {
      form: 3,
      name: fieldValues.businessContactName,
      business: fieldValues.businessName,
      phone: fieldValues.phone,
      email: fieldValues.businessEmail,
      postal: "EC1V 2NX",
      customer: false,
      industry: "industry",
      message: "message",
      demo: false,
      callback: false,
      preferred: "preferred",
      referrerEmail: fieldValues.email,
      referrerName: fieldValues.name,
    };
    props.action({ type: "submit-register-interest", data });
  };

  const handleFields = (name, value) => {
    const cloneFieldValues = { ...fieldValues };
    if (name === "phone") {
      cloneFieldValues["phone"] = value;
      setFieldValues(cloneFieldValues);
    } else {
      cloneFieldValues[name] = value;
      setFieldValues(cloneFieldValues);
    }
  };

  return (
    <>
      <p className={"form-title"}>Get £25 on Business Referrals</p>
      <p className={"form-subtitle"}>
        Earn £25 for every business you refer to REACH and they stay beyond the
        trial period. There is no limit on how many you can refer, so cash in!
        <br />
      </p>
      <TextInputUI
        label="Full Name *"
        value={fieldValues.name}
        onChange={(val) => handleFields("name", val)}
        placeholder="First name & last name"
      />
      <TextInputUI
        label="Email address*"
        value={fieldValues.email}
        onChange={(val) => handleFields("email", val)}
        placeholder={"your_email@example.com"}
      />
      <TextInputUI
        label="Business Name *"
        onChange={(val) => handleFields("businessName", val)}
        value={fieldValues.businessName}
        placeholder="Enter full business name"
      />
      <TextInputUI
        label="Business Contact *"
        onChange={(val) => handleFields("businessContactName", val)}
        value={fieldValues.businessContactName}
        placeholder="First name & last name"
      />
      <TextInputUI
        label="Business Email *"
        value={fieldValues.businessEmail}
        onChange={(val) => handleFields("businessEmail", val)}
        placeholder="business_email@example.com"
      />
      <MuiPhoneNumber
        label="Business Phone *"
        defaultCountry={"gb"}
        className={"popup-input phone"}
        onChange={(val) => handleFields("phone", val)}
        value={fieldValues.phone}
        onlyCountries={["gb"]}
        countryCodeEditable={false}
      />
      {props.errorText && props.errorText !== "" && (
        <div
          style={{
            color: "red",
            marginBottom: "10px",
            fontSize: "14px",
          }}
        >
          {props.errorText}!
        </div>
      )}
      <Recaptcha hanldeRecaptcha={(flag) => setVerifyRecaptcha(flag)} />
      <ButtonUI
        onPress={() => submitForm()}
        disabled={!validate()}
        label={"Submit Business Referral"}
        loading={props.isLoading}
      />
    </>
  );
};

export default RequestDemo;
