import React from "react";
import "./Topbar.scss";
import environment from "../../../environment";

function ReachTopBar(props) {
  return (
    <div className={"topbar cont-mb"} id={"home-top-bar"}>
      <div className={"topbar-content"}>
        <div className="cl-section" style={{ position: "relative" }}>
          <div className="message">
            <div className="description">
              HOME | PERSONAL | FITNESS | HEALTH | PET
            </div>
            <div className="title">
              All-in-one Small Business Management App
            </div>
            <div className="description">
              Get new clients, provide online booking, build reputation, take
              secure payments, view performance reports, run digital marketing
              and more...
            </div>
            <button
              onClick={() =>
                window.open(`${environment.PARTNER_ENDPOINT}/signup`)
              }
              className="pointer cta-btn-type1"
            >
              Join REACH - It's free
            </button>
          </div>
          <div className="event-cal-wrapper">
            <img
              src={props.image}
              alt={"reach-calendar"}
              className="event-cal"
            />
          </div>
        </div>
      </div>
      {/* <img
        className={"topbar-wave-style"}
        src={"/media/topbanner_bottom.svg"}
        alt={"react-topbarbanner"}
      /> */}
      {/* <div className="event-img-cont">
        <img src="./media/business-calendar.png" alt={"reach-calendar"} />
      </div> */}
    </div>
  );
}
export default ReachTopBar;
