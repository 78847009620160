import React from "react";
import "./style.scss";
import ReferBusiness from "./ReferBusiness";
import RegisterInterest from "./RegisterInterest";
import ThankYou from "./ThankYou";
import CallSupportPopup from "./CallSupportPopup";
import EmailSupport from "./EmailSupport";
import OfferRegister from "./OfferRegister";
import { ReactComponent as CancelIcon } from "../../SVG/cancel.svg";
import AppContext from "../../Context";
import axios from "axios";
import environment from "../../environment";
import { Link } from "react-router-dom";
import ClientOffer from "./ClientOffer"

const Popup = () => {
  const contextStore = React.useContext(AppContext);
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    data: { popup },
  } = contextStore;
  const formType = popup.type || "";
  
  const submitForm = (payload) => {
    const newStoreData = contextStore.data;
    newStoreData.popup.errorText = "";
    contextStore.updateData(newStoreData);
    setIsLoading(true);
    axios({
      url: `${environment.API_ENDPOINT}/website/enquiry`,

      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: " application/vnd.aurlis.reach+json; version=1.0",
      },
      data: JSON.stringify(payload.data),
    })
      .then((response) => {
        setIsLoading(false);
        newStoreData.popup.type = "thank-you";
        contextStore.updateData(newStoreData);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        let errorText = "Some error occured";
        if (error.response?.data?.mesg) {
          let temp = error.response?.data?.mesg.split(" ");
          temp.splice(0, 1);
          errorText = temp.join(" ");
        }
        newStoreData.popup.errorText = errorText;
      });
  };
  const getForm = () => {
    switch (formType) {
      case "refer-business":
        return (
          <ReferBusiness
            action={submitForm}
            errorText={popup.errorText}
            isLoading={isLoading}
          />
        );

      case "register-interest":
        return (
          <RegisterInterest
            action={submitForm}
            errorText={popup.errorText}
            isLoading={isLoading}
          />
        );
      case "thank-you":
        return (
          <ThankYou
            action={() => {
              const dataCloned = contextStore.data;
              dataCloned.popup.type = "";
              contextStore.updateData(dataCloned);
            }}
            errorText={popup.errorText}
          />
        );
      case "call-support":
        return (
          <CallSupportPopup
            action={submitForm}
            errorText={popup.errorText}
            isLoading={isLoading}
          />
        );
      case "offer-register":
        return (
          <OfferRegister
            action={submitForm}
            errorText={popup.errorText}
            isLoading={isLoading}
          />
        );
        case "client-offer":
        return (
          <ClientOffer
            action={submitForm}
            errorText={popup.errorText}
            isLoading={isLoading}
          />
        );
      case "email-support":
        return (
          <EmailSupport
            action={submitForm}
            errorText={popup.errorText}
            isLoading={isLoading}
          />
        );
    }
  };
  if (formType === "") {
    return <div />;
  }
  function handleClick() {
    const data = {
      popup: {
        type: "",
      },
    };
    // console.log(contextStore);
    contextStore.updateData(data);
  }
  return (
    <div className="popup-component">
      <div className={`popup-content-wrapper `}>
        <div className={"popup-content"}>
          {getForm()}
          {formType !== "thank-you" && (
            <>
              <p className={"popup-tnc"}>
                By continuing, you are indicating that you accept our
                <br />
                <Link to="/en-gb/terms" target="_blank">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link to="/en-gb/privacy" target="_blank">
                  Privacy Policy.
                </Link>
              </p>
            </>
          )}
        </div>

        <span className={"cancel-btn pointer"} onClick={() => handleClick()}>
          <CancelIcon />
        </span>
      </div>
    </div>
  );
};
export default Popup;
