import { IAction } from "../Models"
import { FEATUREDPARTNERS, FIREBASEINFO, SENDSMSLINK, TOPCATEGORIES } from "./types"
import firebase from "../Firebase";
import { store } from "../Store";
const firebaseGuestLogin = () => {
    console.log("firebaseguestlogin")
    firebase
        .auth()
        .signInAnonymously()
        .then((result) => {
            // Signed in..
            console.log(result);
            store.dispatch(getFirebaseUserInfo())
        })
        .catch((error) => {
            console.log(error);
            const errorCode = error.code;
            const errorMessage = error.message;
            // ...
        });
}
const getFirebaseUserInfo = (): IAction => {
    return {
        type: FIREBASEINFO,
        isFirebaseCall: true
    }
}
const getTopCategories = (): IAction => {
    return {
        type: TOPCATEGORIES,
        isApi: true,
        payload: {
            url: `industry/popular?item=industry&type=5&industry=&bottom=false&offset=0&limit=10`,
            data: {},
            method: "GET"
        }
    }
}
const getFeaturedPartners = (): IAction => {
    return {
        type: FEATUREDPARTNERS,
        isApi: true,
        payload: {
            url: `/business/featured?offset=0&limit=10&locType=type&locData=sutton`,
            data: {},
            method: "GET"
        }
    }
}
const sendSmsLink = (payload, callback): IAction => {
    return {
        type: SENDSMSLINK,
        isApi: true,
        payload: {
            url: `/website/enquiry`,
            data: payload,
            method: "POST",
            callback
        }
    }
}
export {
    getFirebaseUserInfo,
    firebaseGuestLogin,
    getTopCategories,
    getFeaturedPartners,
    sendSmsLink
}