import React, { useState } from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import ButtonUI from "../../UIElements/ButtonUI";
import Recaptcha from "./Recaptcha";
import TextInputUI from "../../UIElements/TextInputUI";
import { TextareaAutosize, Checkbox } from "@material-ui/core";

const defaultValues = {
  firstName: "",
  lastName: "",
  businessEmail: "",
  businessName: "",
  businessContactName: "",
  postCode: "",
  phone: "",
  notes: "",
  callback: false,
};
interface IProps {
  action: (data: any) => void;
  errorText: string;
  isLoading: boolean;
}
export default function EmailSupport(props: IProps) {
  const [fieldValues, setFieldValues] = useState(defaultValues);
  const [verifyRecaptcha, setVerifyRecaptcha] = useState(false);

  const validate = () => {
    const validateFlag =
      fieldValues.firstName !== "" &&
      fieldValues.lastName !== "" &&
      validateEmail(fieldValues.businessEmail) &&
      fieldValues.phone.length >= 15 &&
      verifyRecaptcha;
    return validateFlag;
  };

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const submitForm = () => {
    const data = {
      form: 5,
      name: [fieldValues.firstName, fieldValues.lastName].join(" "),
      business: "business",
      phone: fieldValues.phone,
      email: fieldValues.businessEmail,
      postal: "EC1V 2NX",
      customer: false,
      industry: "industry",
      message: fieldValues.notes || "message",
      demo: false,
      callback: fieldValues.callback,
      preferred: "preferred",
      referrerEmail: "",
      referrerName: "",
    };
    props.action({ type: "submit-register-interest", data });
  };

  const handleFields = (name, value) => {
    const cloneFieldValues = { ...fieldValues };
    if (name === "phone") {
      cloneFieldValues["phone"] = value;
      setFieldValues(cloneFieldValues);
    } else {
      cloneFieldValues[name] = value;
      setFieldValues(cloneFieldValues);
    }
  };

  return (
    <>
      <p className={"form-title"}>Request a callback</p>
      <p className={"form-subtitle"}>
        Please fill the below form, one of our friendly Support team member will
        get back to you, to understand how can we help you grow your business.
      </p>
      <div style={{ display: "flex", gap: "20px" }}>
        <TextInputUI
          label="First Name *"
          value={fieldValues.firstName}
          onChange={(val) => handleFields("firstName", val)}
          placeholder="Enter your first name"
        />

        <TextInputUI
          label="Last Name *"
          onChange={(val) => handleFields("lastName", val)}
          value={fieldValues.lastName}
          placeholder="Enter your last name"
        />
      </div>

      <TextInputUI
        label="Business Email *"
        value={fieldValues.businessEmail}
        onChange={(val) => handleFields("businessEmail", val)}
        placeholder={"your_email@example.com"}
      />

      <MuiPhoneNumber
        label="Phone number"
        defaultCountry={"gb"}
        className={"popup-input phone"}
        onChange={(val) => handleFields("phone", val)}
        value={fieldValues.phone}
        onlyCountries={["gb"]}
        countryCodeEditable={false}
      />
      <div className="f-label">What is your question?</div>
      <TextareaAutosize
        aria-label="empty textarea"
        placeholder="Tell us how we can help"
        minRows={5}
        style={{
          padding: 10,
          border: "1px solid rgba(36, 39, 44, 0.15)",
          borderRadius: 4,
        }}
        value={fieldValues.notes}
        onChange={(e) => handleFields("notes", e.target.value)}
      />
      {props.errorText && props.errorText !== "" && (
        <div
          style={{
            color: "red",
            marginBottom: "10px",
            fontSize: "14px",
          }}
        >
          {props.errorText}!
        </div>
      )}

      <Recaptcha hanldeRecaptcha={(flag) => setVerifyRecaptcha(flag)} />
      <div className="f-label">
        <Checkbox
          color="primary"
          checked={fieldValues.callback}
          onChange={(e) => handleFields("callback", e.target.checked)}
        />
        I'd like to be contacted with special offers and promotions
      </div>
      <ButtonUI
        onPress={() => submitForm()}
        disabled={!validate()}
        label={"Submit"}
        loading={props.isLoading}
      />
    </>
  );
}
