import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

interface IProps {
  label: string;
  onPress: () => void;
  disabled?: boolean;
  loading?: boolean;
  style?: React.CSSProperties
}
export default function ButtonUI(props: IProps) {
  const { label = "", onPress, disabled = false, loading = false } = props;
  const classes = useStyles();
  return (
    <Button
      className={classes.btn}
      variant="contained"
      color="primary"
      style={{
        background: "#007fff",
        opacity: disabled ? 0.5 : 1,
        color: "white",
        ...props.style
      }}
      onClick={onPress}
      disabled={disabled}
    >
      {!loading ? (
        label
      ) : (
        <div style={{}}>
          <CircularProgress color="inherit" size={25} />
        </div>
      )}
    </Button>
  );
}
const useStyles: any = makeStyles({
  btn: {
    width: "100%",
    height: 40,
  },
});
