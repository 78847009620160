import React from "react";
import { Button } from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import environment from "../../../environment";
import { clientThemeColor } from "../../../Util";

export default function FindPartners() {
  const [query, setQuery] = React.useState("");
  return (
    <div className="cl-section top flex flex-row">
      <div className="justify-center flex flex-col wrapper">
        <h1 className="">Instantly book local services near you</h1>
        <p className="desc">
          Find trusted and vetted businessess for your service needs!
        </p>
        <div style={{ display: "flex" }}>
          <input
            placeholder="What services do you need ?"
            type="text"
            className="service-input"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
          <Button
            className=""
            variant="contained"
            color="primary"
            style={{
              backgroundColor: clientThemeColor,
              borderRadius: "0px",
              height: 56,
              // width: 320,
            }}
            onClick={() => {
              const url = `${environment.CLIENT_ENDPOINT}/client/search?type=query&id=&name=${query}`;
              // handleWindowUrl(`/client/search`);
              window.open(url, "_self");
            }}
          >
            <i className="fa fa-search" />
          </Button>
        </div>
      </div>
      <LazyLoadImage
        alt="reach-features"
        style={{ width: "max(295px, 30%)" }}
        src="/media/client-searchnow.webp"
      />
    </div>
  );
}
