import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import environment from "../environment";

const API_URL = environment.API_ENDPOINT;

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json, text/plain, */*"
    }
});

axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {

    const authInfo: any = localStorage.getItem("sessionDetail") || "{}";
    const obj = JSON.parse(authInfo) || {}

    config.headers["Aurlis-Authorisation"] = `${obj.type || ""} ${obj.token || ""}`;
    return config;
});

axiosInstance.interceptors.response.use((response: AxiosResponse): any => {
    return response.data;
})

export default axiosInstance;
