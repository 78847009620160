import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ButtonUI from "../../UIElements/ButtonUI";
import TextInputUI from "../../UIElements/TextInputUI";
import { clientThemeColor } from "../../Util";

interface IProps {
  action: (data: any) => void;
  errorText: string;
  isLoading: boolean;
}
export default function ClientOffer(props: IProps) {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
  });
  function handleChange(name, val) {
    setFormData({ ...formData, [name]: val });
  }

  function submitForm() {

    const data = {
      form: 8,
      name: formData.name,
      business: "business",
      phone: "",
      email: formData.email,
      postal: "postal",
      customer: false,
      industry: "industry",
      message: "Get 3 months of free subscription on us!",
      demo: false,
      callback: false,
      preferred: "preferred",
      referrerEmail: "referrerEmail",
      referrerName: "referrerName",
    };
    props.action({ type: "submit-client-offer", data });
  }

  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  function btnValidation() {
    return (
      formData.name !== "" &&
      validateEmail(formData.email) 
      // &&
      // formData.phoneNumber.length >= 15
    );
  }
  return (
    <div>
      <div className={classes.title}>Claim £10 off your first booking</div>
      <div className={classes.description}>
      Thank you for helping us launch, we are offering a £10 credit for all new customers who register
        
      </div>
      <TextInputUI
        label="Full name *"
        placeholder="Enter full name"
        value={formData.name}
        onChange={(val) => handleChange("name", val)}
      />
      <div style={{ height: "10px" }} />
      <TextInputUI
        label="Email *"
        value={formData.email}
        onChange={(val) => handleChange("email", val)}
        placeholder="Enter email address"
      />
      {/* <div style={{ height: "10px" }} /> */}
      {/* <MuiPhoneNumber
        label="Phone number *"
        defaultCountry={"gb"}
        onChange={(val) => handleChange("phoneNumber", val)}
        className={"popup-input phone"}
        name="phone"
        value={formData.phoneNumber}
        onlyCountries={["gb"]}
        countryCodeEditable={false}
        style={{ width: "100%", marginBottom: 10 }}
      /> */}
      <div style={{ height: "10px" }} />
      {props.errorText && props.errorText !== "" && (
        <div
          style={{
            color: "red",
            marginBottom: "10px",
            fontSize: "14px",
          }}
        >
          {props.errorText}!
        </div>
      )}
      <ButtonUI
        label="CLAIM THIS OFFER"
        onPress={submitForm}
        disabled={!btnValidation()}
        loading={props.isLoading}
        style={{backgroundColor:clientThemeColor}}
      />
    </div>
  );
}
const useStyles: any = makeStyles({
  title: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: 18,
    marginBottom: 10,
  },
  description: {
    fontSize: 14,
    marginBottom: 20,
    textAlign: "center",
  },
});
