import React from "react";
import Carousel from "./Carousel";

export default function Testimonials() {
  return (
    <section className="cl-section section-block">
      <label>Trusted by our customers!</label>
      <Carousel
        cols={3}
        rows={1}
        gap={30}
        responsiveLayout={[
          {
            breakpoint: 992,
            cols: 2,
            rows: 1,
            gap: 10,
          },
        ]}
        mobileBreakpoint={767}
        items={[
          {
            img: "/media/customer-vincent.jpg",
            name: "Vincent",
            location: "Sutton",
            review:
              "Transparent price and instant booking, the EV installation job was completed as expected.",
          },
          {
            img: "/media/customer-gabriel.jpg",
            name: "Gabriel",
            location: "Croydon",
            review:
              "The app made my bedroom painting job booking quick and easy, and the painter was friendly & professional, would book again using REACH.",
          },
          {
            img: "/media/customer-haoyu.jpg",
            name: "Haoyu",
            location: "Worcester Park",
            review:
              "It works, booked a mobile screen repair and showed up to the shop, the guy fixed it on the price shown on the app. nice one!",
          },
          {
            img: "/media/customer-laura.jpg",
            name: "Laura",
            location: "Greenwich",
            review:
              "Great cleaning service! The booking app was easy and the cleaner was friendly, and did an fantastic job. Highly recommend!",
          },
          {
            img: "/media/customer-charlotte.jpg",
            name: "Charlotte",
            location: "Streatham",
            review:
              "Wanted to try this app, I'm really pleased the plumber turned up on time and fixed my leaking tap under 1 hour. Amazing!",
          },
          {
            img: "/media/customer-lee.jpg",
            name: "Wong",
            location: "Richmond",
            review:
              "Having booked two services so far and both been delivered good, happy to recommend the app.",
          },
        ]}
        renderItem={(item) => (
          <div className="card car-item testimonial" style={{height:"100%"}}>
            <div className="profile">
              <img
                width="70"
                height="70px"
                src={item.img}
                alt={"testimonial-customers"}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  paddingBlock: "5px",
                }}
              >
                <p className="user">{item.name}</p>
                <p className="location">{item.location}</p>
              </div>
            </div>
            <p className="comment">“{item.review}</p>
            <div className="rating">
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
        )}
      />
    </section>
  );
}
