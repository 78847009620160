import React from "react";
import PlansJSON from "../../../Data/Plans.json";
import environment from "../../../environment";
import ButtonCta from "../../../Components/ButtonCta";
import "./styles.scss";

function Pricing(props) {
  const { reference } = props;
  const { features, plans } = PlansJSON;
  const [plansKeys, setPlanKeys] = React.useState(Object.keys(plans));
  const [selectedPlan, setSelectedPlan] = React.useState(plansKeys[0]);
  const [activeSection, setActiveSection] = React.useState([]);
  React.useEffect(() => {
    if (window.innerWidth >= 768) {
      const newPlanKeys = PlansJSON.features.map((item) => item.sectionHeader);
      setActiveSection(newPlanKeys);
    }
  }, []);
  const getValue = (value) => {
    if (!value) {
      return false;
    } else {
      return value;
    }
  };
  const renderRow = (feature) => {
    return (
      <div key={feature.key} className="p-row">
        <div className={`p-col ${feature.sub ? "p-col-sub" : ""}`}>
          {feature.label}
        </div>
        {plansKeys.map((item, idx) => {
          const value = getValue(PlansJSON.plans[item][feature.key]);
          const className = value === true ? "icon-checkmark" : "";
          const newValue = value === true ? "✔" : value === false ? "-" : value;
          return (
            <div
              key={`${item}-row-${idx}`}
              className={`${className}  p-col ${
                item !== selectedPlan ? "not-active" : ""
              }`}
            >
              {newValue}
            </div>
          );
        })}
      </div>
    );
  };
  const renderSection = (section) => {
    const isSectionActive = activeSection.includes(section.sectionHeader);
    return (
      <div
        className={`accordian ${isSectionActive ? "active" : ""}`}
        id={`pricing-${section.sectionHeader}`}
      >
        <div
          className="sectionTitle p-row pointer"
          onClick={() => {
            let newList = JSON.parse(JSON.stringify(activeSection));
            if (isSectionActive) {
              newList = newList.filter((d) => d !== section.sectionHeader);
            } else {
              newList.push(section.sectionHeader);
            }
            setActiveSection(newList);
            // setTimeout(() => {
            //   const position =
            //     document.getElementById(`pricing-${section.sectionHeader}`)
            //       .offsetTop - 75;
            //   window.scrollTo({ top: position, behavior: "smooth" });
            // }, 10);
          }}
        >
          <div className="p-col">
            <span style={{ fontSize: 22 }}>{isSectionActive ? "-" : "+"}</span>
            {section.sectionHeader}
          </div>
        </div>
        <div className="accordian-content">
          {section.sectionItems.map((row) => renderRow(row))}
        </div>
      </div>
    );
  };
  const renderExpandAndCollapse = () => {
    return (
      <div className="expand-collapse">
        <span
          onClick={() => {
            const allSections = PlansJSON.features.map((d) => d.sectionHeader);
            setActiveSection(allSections);
          }}
        >
          Expand all
        </span>{" "}
        |{" "}
        <span
          onClick={() => {
            setActiveSection([]);
          }}
        >
          Collapse all
        </span>
      </div>
    );
  };
  return (
    <div id="pricing-component" className="cont-mb cl-section" ref={reference}>
      <div className="pricing-tabs">
        {Object.keys(plans).map((plan, idx) => {
          const { label } = plans[plan].plan_config;
          return (
            <div
              key={label}
              className={`pricing-tab ${
                selectedPlan === plan ? "active" : ""
              } pointer`}
              onClick={() => {
                // setPlanKeys([plan]);
                setSelectedPlan(plan);
              }}
            >
              {label}
            </div>
          );
        })}
      </div>
      <div className="p-head">
        <div className="p-row">
          <div className="p-col" />
          <div className="p-col sub-title">
            No credit card required. No commitment, downgrade or cancel your
            account anytime.
          </div>
        </div>
        <div className="p-row plan-info">
          <div className="p-col" />
          {plansKeys.map((plan, idx) => {
            console.log(plan);
            const { label, amount } = plans[plan].plan_config;
            return (
              <div key={label} className="p-col">
                <p className="plan-name">{label}</p>
                <p className="amount">£{amount}</p>
                {/* <p className="amount-period">Per Month</p> */}
                <p className="frequency">Billed monthly</p>
                <div className="trail-info">
                  {plan === "free" ? "" : "Free 90 days trail"}
                  {plan === "pro" && (
                    <span>
                      <img
                        src="/media/pricing-arrow.svg"
                        alt="reach"
                        className="pricing-arrow"
                      />
                    </span>
                  )}
                </div>
                <ButtonCta
                  // className="cta-btn-type1"
                  type="type1"
                  onClick={() =>
                    window.open(`${environment.PARTNER_ENDPOINT}/signup`)
                  }
                  label={plan === "free" ? "Sign up free" : "Start free trail"}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="plan-info-sm">
        <div className="p-row">
          <div className="p-col">Price / Month</div>
          {plansKeys.map((item, idx) => {
            return (
              <div
                className={`p-col ${item !== selectedPlan ? "not-active" : ""}`}
                key={idx}
              >
                £{plans[item].plan_config.amount}
              </div>
            );
          })}
        </div>
        {plansKeys.map((item, idx) => (
          <div
            className={`p-col ${item !== selectedPlan ? "not-active" : ""}`}
            key={item}
          >
            {item === "pro" ? (
              <div className="trail-info">
                Free 90 days trail{" "}
                <span>
                  <img
                    src="/media/pricing-arrow.svg"
                    alt="reach"
                    className="pricing-arrow"
                  />
                </span>
              </div>
            ) : (
              <div className="trail-info" />
            )}
            <ButtonCta
              type="type1"
              onClick={() =>
                window.open(`${environment.PARTNER_ENDPOINT}/signup`)
              }
              label={item === "pro" ? "Start free trail" : "Sign up free"}
            />
          </div>
        ))}
      </div>
      {renderExpandAndCollapse()}
      <div className="p-body">
        {features.map((section, idx) => {
          return (
            <div
              className="p-section"
              key={section.sectionHeader.replace(/ /g, "")}
            >
              {renderSection(section)}
            </div>
          );
        })}
        {renderExpandAndCollapse()}
        <div className="p-row bottom-cta" style={{ borderBottom: "none" }}>
          <div className="p-col" />

          {plansKeys.map((item, idx) => (
            <div
              className={`p-col ${item !== selectedPlan ? "not-active" : ""}`}
              key={item}
            >
              {item === "pro" ? (
                <div className="trail-info">
                  Free 90 days trail{" "}
                  <span>
                    <img
                      src="/media/pricing-arrow.svg"
                      alt="reach"
                      className="pricing-arrow"
                    />
                  </span>
                </div>
              ) : (
                <div className="trail-info" />
              )}
              <ButtonCta
                type="type1"
                onClick={() =>
                  window.open(`${environment.PARTNER_ENDPOINT}/signup`)
                }
                label={item === "pro" ? "Start free trail" : "Sign up free"}
              />
            </div>
          ))}
        </div>
      </div>

      <div className={"coming-soon"}>
        <p>
          * Coming soon - this is a planned feature, will be delivered free of
          cost in the next few months with software update
        </p>
        <p># You can purchase additional marketing SMS for £3.99 per 100 SMS</p>
        <p>
          + Each plan includes one user, for additional users(plus staff
          schedule) you will be charged the specified rate
        </p>
      </div>
    </div>
  );
}
export default Pricing;
