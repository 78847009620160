import React, { useState } from "react";
import { InputLabel, Select, FormControl, MenuItem } from "@material-ui/core/";
import MuiPhoneNumber from "material-ui-phone-number";
import Recaptcha from "./Recaptcha";
import CarouselData from "../../Data/IndustryData.json";
import ButtonUI from "../../UIElements/ButtonUI";
import TextInputUI from "../../UIElements/TextInputUI";

const defaultValues = {
  fullName: "",
  businessEmail: "",
  businessName: "",
  postCode: "",
  phone: "",
  industry: "",
};
interface IProps {
  action: (data: any) => void;
  errorText: string;
  isLoading: boolean;
}
const RegisterInterest = (props: IProps) => {
  const [fieldValues, setFieldValues] = useState(defaultValues);
  const [verifyRecaptcha, setVerifyRecaptcha] = useState(false);

  const validate = () => {
    const validateFlag =
      fieldValues.fullName !== "" &&
      fieldValues.businessName !== "" &&
      validateEmail(fieldValues.businessEmail) &&
      fieldValues.postCode !== "" &&
      fieldValues.industry !== "" &&
      fieldValues.phone.length >= 15 &&
      verifyRecaptcha;
    return validateFlag;
  };
  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const submitForm = () => {
    const data = {
      form: 2,
      name: fieldValues.fullName,
      business: fieldValues.businessName,
      phone: fieldValues.phone,
      email: fieldValues.businessEmail,
      postal: fieldValues.postCode,
      customer: false,
      industry: fieldValues.industry,
      message: "Want to join the network",
      demo: true,
      callback: false,
      preferred: "preferred",
      referrerEmail: "referrerEmail",
      referrerName: "referrerName",
    };
    props.action({ type: "submit-register-interest", data });
    return;
  };
  const handleFields = (name, value) => {
    const cloneFieldValues = { ...fieldValues };
    if (name === "phone") {
      cloneFieldValues["phone"] = value;
      setFieldValues(cloneFieldValues);
    } else {
      cloneFieldValues[name] = value;
      setFieldValues(cloneFieldValues);
    }
  };
  const handleRecaptcha = (flag) => {
    setVerifyRecaptcha(flag);
  };
  const industryNames = CarouselData.map((carouselItem) => carouselItem.name);
  return (
    <>
      <p className={"form-title"}>
        We'll set up a personalized
        <br /> demo just for you
      </p>
      <TextInputUI
        label="Full Name *"
        value={fieldValues.fullName}
        onChange={(val) => handleFields("fullName", val)}
        placeholder={"First name & last name"}
      />
      <TextInputUI
        label="Business Name *"
        value={fieldValues.businessName}
        onChange={(val) => handleFields("businessName", val)}
        placeholder={"REACH Services"}
      />
      <TextInputUI
        label="Business Email *"
        value={fieldValues.businessEmail}
        placeholder={"youremail@example.com"}
        onChange={(val) => handleFields("businessEmail", val)}
      />
      <MuiPhoneNumber
        label="Business Phone"
        defaultCountry={"gb"}
        onChange={(val) => handleFields("phone", val)}
        className={"popup-input phone"}
        name="phone"
        value={fieldValues.phone}
        onlyCountries={["gb"]}
        countryCodeEditable={false}
      />

      <TextInputUI
        label="Business Postal Code"
        value={fieldValues.postCode}
        placeholder={"EC1V 2NX"}
        onChange={(val) => handleFields("postCode", val)}
      />
      <FormControl variant="filled" className="popup-input">
        <InputLabel id="demo-simple-select-filled-label">
          Select Industry
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          name="industry"
          value={fieldValues.industry}
          onChange={(e) => handleFields("industry", e.target.value)}
          renderValue={
            fieldValues.industry !== "" ? undefined : () => <div>Answer</div>
          }
        >
          {industryNames.map((industry, idx) => (
            <MenuItem value={industry} key={idx + industry.replace(" ", "")}>
              {industry}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {props.errorText && props.errorText !== "" && (
        <div
          style={{
            color: "red",
            marginBottom: "10px",
            fontSize: "14px",
          }}
        >
          {props.errorText}!
        </div>
      )}
      <Recaptcha hanldeRecaptcha={handleRecaptcha} />
      <ButtonUI
        onPress={() => submitForm()}
        disabled={!validate()}
        label={"Request a Demo"}
        loading={props.isLoading}
      />
    </>
  );
};

export default RegisterInterest;
