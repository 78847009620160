import React, { CSSProperties } from "react";

interface IProps {
  image: string;
  children: React.ReactNode;
}
export default function MainBackgroundImage(props: IProps) {
  const containerStyle: CSSProperties = {
    height: 500,
    position: "relative",
    overflow: "hidden",
    backgroundImage: `url(${props.image})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
  };
  const contentStyle: CSSProperties = {
    position: "absolute",
    height: "100%",
    width: "100%",
  };
  return (
    <div style={containerStyle} className="cont-mb">
      <div style={contentStyle}>{props.children}</div>
      {/* <img src={props.image} alt="reach" style={imageStyle} /> */}
    </div>
  );
}
