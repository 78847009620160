import React from "react";
import CarouselComponent from "./Carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import environment from "../../../environment";
const imageHeight: number = 115;

export default function TopCategory(props) {
  return (
    <div className="cl-section section-block hire-people">
      <label>How it works!</label>
      <div className="hire-people-item-container">
        {props.hireMetaData.map((item, idx) => {
          return (
            <>
              <div
                className="hire-people-item-content"
                onClick={() => {
                  //   const url = `${environment.CLIENT_ENDPOINT}/search?type=industry&id=${item.id}&name=${item.name}`;
                  //   window.open(url, "_self");
                }}
                style={{ padding: "0px" }}
              >
                <LazyLoadImage
                  width="100%"
                  height={imageHeight}
                  src={item.img || "/media/coverimage.png"}
                  style={{ minHeight: imageHeight }}
                  alt={"reach-top-categories"}
                  effect="blur"
                />
                <p
                  style={{
                    paddingBlock: 12,
                    fontSize: "clamp(12px,3.5vw,16px)",
                  }}
                  className="text-center font-700"
                >
                  {item.title}
                </p>
                <p
                  style={{
                    
                    fontSize: "clamp(12px,3.5vw,16px)",
                  }}
                  className="text-center"
                >
                 {item.content}
                </p>
              </div>
              {idx < props.hireMetaData.length - 1 && (
                <div className="hire-people-item-arrow">
                  <img
                    src="/media/green-arrow-forward.png"
                    alt="reach"
                    className="pricing-arrow"
                  />
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
}
