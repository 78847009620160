import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./style.scss";

export default function FeatureItem(props) {
  const { data = {}, extraClass = "" } = props;
  const {
    name = "",
    bulletPoints = [],
    smallDescription = "",
    image = "",
    url = "/feature",
  } = data;
  return (
    <div className={`features-item cont-mb ${extraClass}`}>
      <LazyLoadImage
        className={"pickup-image"}
        src={`${image}`}
        alt="Reach Feature"
        effect="blur"
      />
      <div className={"pickup-content"}>
        <h2 className={"title"}>{name}</h2>
        {bulletPoints.map((option, idx) => (
          <div key={idx} className={"option"}>
            <img src={"/media/check.svg"} alt={"Reach"} />
            <span className="roboto-font">{option}</span>
          </div>
        ))}
        <Link className="feature-item-link pointer" to={url + "?bullet=false"}>
          Learn more
        </Link>
      </div>
    </div>
  );
}
