import IndustryData from "./Data/IndustryData.json";
import FeaturesData from "./Data/Features.json";

export function mailTo() {
  var mail = document.createElement("a");
  mail.href = "mailto:hello@reach.biz";
  mail.click();
}
export function parseLocationPath(path) {
  var search = path.substring(1);
  if (!search) {
    return {};
  }
  const res = JSON.parse(
    '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function (key, value) {
      return key === "" ? value : decodeURIComponent(value);
    }
  );
  return res;
}
export function featuresWithIndustryImages() {
  const industry =
    localStorage.getItem("personalizedIndustry") || IndustryData[0].url;
  if (industry) {
    const industryFeatures =
      IndustryData.find((d) => d.url === industry)?.Features || [];
    const clonedFeaturesData = JSON.parse(JSON.stringify(FeaturesData));
    const modifiedFeaturesData = clonedFeaturesData.reduce((acc, item) => {
      const industryFeatureImage =
        industryFeatures.find((d) => d.id === item.id)?.image || "";
      if (industryFeatureImage) {
        item.image = industryFeatureImage;
      }
      acc.push(item);
      return acc;
    }, []);

    return modifiedFeaturesData;
  }
  return [];
}
export function industryRelatedBanner() {
  const industry =
    localStorage.getItem("personalizedIndustry") || IndustryData[0].url;
  if (industry) {
    const industryImage =
      IndustryData.find((d) => d.url === industry)?.banner?.mockup || "";
    return industryImage;
  }
  return "";
}
export const handleFirebaseInfo = (payload: any) => {
  const { fire_user, tokenDetails } = payload;
  const user = fire_user;
  const { expirationTime, token } = tokenDetails;
  const { providerData } = user;
  const authInfo = {
    expiryTime: new Date(expirationTime).getTime().toString().substring(0, 10),
    idToken: token,
    uid: user.uid || "",
  };
  let firstName = "";
  let lastName = "";

  const data = {
    firstName,
    lastName,
    email: providerData[0]?.email || "",
  };
  return { data, authInfo };
};
export function isClientRoute() {
  const path = window.location.pathname;
  if (path.includes("client") || path.includes("marketplace") || path === "/") {
    return true;
  }
  return false;
}
export function handleWindowUrl(url) {
  const origin = window.location.origin;
  window.open(origin + url, "_self");
}
export function getThemeColor() {
  return isClientRoute() ? clientThemeColor : partnerThemeColor;
}
export const clientThemeColor= "#0D771E"
export const partnerThemeColor= "#007fff";

