import FeatureItem from "./FeatureItem";
import Banner from "../../Components/Banner";
import IndustryData from "../../Data/IndustryData.json";
import FeaturesData from "../../Data/Features.json";
import { useLocation } from "react-router-dom";
import "./style.scss";
import { parseLocationPath } from "../../Util";
import { useMemo } from "react";

export default function FeatureInfoComponent({ subfeatures, feature }) {
  const location = useLocation();
  const list1 = subfeatures.slice(0, 2);
  const list2 = subfeatures.slice(2);
  const { description = "", banner = {} } = feature || {};
  const { title, brief } = banner;

  const personalizedIndustry = localStorage.getItem("personalizedIndustry");
  const relatedIndustryImage = useMemo(() => {
    const featureId =
      FeaturesData.find((d) => location.pathname.includes(d.url))?.id || "";
    if (feature) {
      const industry = personalizedIndustry || IndustryData[0].url;
      if (industry) {
        const industryObj = IndustryData.find((d) => d.url === industry);
        return (
          industryObj.Features.find((d) => d.id === featureId)?.image || ""
        );
      }
    }
    return "";
  }, [location.pathname]);
  const bannerData = {
    image: relatedIndustryImage || "",
    name: title,
    brief,
  };
  const isBulletShow = useMemo(() => {
    const parsedJson = parseLocationPath(location.search);
    console.log(parsedJson);
    return (parsedJson?.bullet && parsedJson.bullet === "true") || false;
  }, [location.search]);
  return (
    <div className="feature-main" id="feature-info">
      <Banner data={bannerData} />
      <div className="title cl-section cont-mb text-center">{description}</div>
      <div className="cl-section">
        {isBulletShow && <FeatureItem data={feature} extraClass={""} />}
        <div className="side-by-side cont-mb">
          {list1.map((item, idx) => (
            <FeatureItem
              key={idx}
              data={item}
              extraClass={`pickup-component${idx}`}
            />
          ))}
          {list2.map((item, idx) => (
            <FeatureItem
              key={idx}
              data={item}
              extraClass={`pickup-component${idx}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
