import React, { useContext } from "react";
import AppContext from "../../../Context";
import "./SignupCatalog.scss";
function SignupCatalog() {
  const contextStore = useContext(AppContext);
  function handleClick() {
    const data = {
      popup: {
        type: "register-interest",
        errorText: "",
      },
    };
    // console.log(contextStore);
    contextStore.updateData(data);
  }
  return (
    <div className={"SignupCatalog cont-mb"}>
      <p className="title">How can REACH help you grow your business?</p>
      <p className={"sub-title roboto-font"}>
        The REACH business app helps you automate and manage your business
        efficiently. New Customers, Online Appointments, Secure Payments,
        Business Reputation and Digital Marketing are some of the many features
        used to super-charge your business growth.
      </p>
      {/* <button>Signup for free</button> */}
      <button onClick={() => handleClick()} className="cta-btn-type1">
        Request a demo
      </button>
    </div>
  );
}
export default SignupCatalog;
