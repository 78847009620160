import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import "./style.scss";

export default function PickUpBusinessComponent(props) {
  const { data = {}, extraClass = "", industryId = "" } = props;
  const {
    name = "",
    bulletPoints = [],
    smallDescription = "",
    image = "",
    url = "",
  } = data;
  return (
    <div className={`pickup-component cont-mb ${extraClass}`}>
      <LazyLoadImage
        className={"pickup-image"}
        src={`${image}`}
        alt="Reach Feature"
        effect="blur"
      />
      <div className={"pickup-content"}>
        <h2 className={"title"}>{name}</h2>
        <p className={"description roboto-font"}>{smallDescription}</p>
        {bulletPoints.map((option, idx) => (
          <div key={idx} className={"option"}>
            <img src={"/media/check.svg"} alt={"Reach"} />
            <span className="roboto-font">{option}</span>
          </div>
        ))}
        <Link
          className="feature-item-link pointer"
          to={`${url}?src=${industryId}&bullet=false`}
        >
          Learn more
        </Link>
      </div>
    </div>
  );
}
