import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { ReactComponent as ReachLogo } from "../SVG/reach-logo.svg";
import AppContext from "../Context";
import { isClientRoute } from "../Util";

const partnerMenuItems = [
  { title: "Request Demo", url: "register-interest" },
  { title: "Refer a Business", url: "refer-business" },
  { title: "Request a Callback", url: "call-support" },
];
const clientMenuItems = [
  { title: "Terms of service", url: "/en-gb/terms" },  
  { title: "Privacy policy", url: "/en-gb/privacy" },
  { title: "Refer a Business", url: "refer-business" },
];
const Footer = () => {
  const contextStore = React.useContext(AppContext);
  const [headerType, setHeaderType] = useState<string>("type1");
  const [menuItems, setMenuItems] = useState<any>(partnerMenuItems);
  const history = useHistory();

  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    let type = headerType;
    let items = menuItems;

    if (isClientRoute()) {
      type = "type2";
      items = clientMenuItems;
    } else {
      type = "type1";
      items = partnerMenuItems;
    }
    if (type !== headerType) {
      setHeaderType(type);
      setMenuItems(items);
    }
  }, [location.pathname]);
  const handleLinks = (url) => {
    if (url) {
      const data = {
        popup: {
          type: url,
          errorText: "",
        },
      };
      contextStore.updateData(data);
    }
  };
  return (
    <>
      <div className="cl-section  footer" id="footer" data-type={headerType}>
        <div className="footer-content">
          <div className="footer-logo">
            <ReachLogo />
          </div>
          <ul className={"footer-list"}>
            {menuItems.map((item, idx) => (
              <li
                key={idx}
                className="roboto-font pointer"
                onClick={() => handleLinks(item.url)}
              >
                {item.title}
              </li>
            ))}
          </ul>
          <div className={"footer-contact"}>
            <ul className={"footer-links"}>
              <li>
                <a
                  href="https://www.facebook.com/ReachBiz247/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="facebook"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/ReachBiz247"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="twitter"
                >
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/reachbiz247/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="linkedin"
                >
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/ReachBiz247/"
                  target="_blank"
                  rel="noreferrer"
                  aria-label="instagram"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
            </ul>
            <a className={"footer-phone roboto-font"} href="tel:+442034884070">
              +44 203 488 4070
            </a>
            <div
              className={"footer-email roboto-font pointer"}
              onClick={() => {
                // handleLinks("email-support")
                history.push("/en-gb/contact-us")
                window.open("/en-gb/contact-us","_self")
              }}
            >
              Email Support
            </div>
            {/* <a
              href="mailto:hello@reach.biz"
              target=""
              className={"footer-email roboto-font"}
            >
              hello@reach.biz
            </a> */}
          </div>
        </div>
        <p className="footer-copyright-text">
          © 2023 REACH Services Network Limited, a limited company registered in
          England and Wales under company number 7307901
        </p>
      </div>
    </>
  );
};

export default Footer;
