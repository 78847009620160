import React from "react";
import Carousel from "./Carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import environment from "../../../environment";

export default function Featured(props) {
  return (
    <div className="cl-section section-block">
      <label>Featured Partners - Greater London</label>
      <div className="featured-home-carousel-container">
        <Carousel
          cols={4}
          rows={1}
          gap={15}
          responsiveLayout={[
            {
              breakpoint: 992,
              cols: 3,
              rows: 1,
              gap: 10,
            },
            {
              breakpoint: 768,
              cols: 3,
              rows: 1,
              gap: 10,
            },
          ]}
          mobileBreakpoint={767}
          items={props.featuredPartners}
          renderItem={(item) => (
            <div
              className="car-item pointer"
              style={{ padding: 0, position: "relative", borderRadius: 4 }}
              key={item?.partner?.id}
              onClick={() => {
                const url = `${environment.CLIENT_ENDPOINT}/client/business/${item.partner.publicUrl.replace(".reach.biz", "")}`;
                window.open(url,"_self");
              }}
            >
              <LazyLoadImage
                width="100%"
                height="180px"
                style={{ minHeight: "180px" }}
                src={item?.cover || "/media/coverimage.png"}
                alt={item?.partner?.name || "reach partner"}
                effect="blur"
              />
              <Rating rating={item?.review?.rating || 0} />
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: 12,
                    gap: 10,
                  }}
                >
                  <div style={{ flex: 1, minWidth: 0 }}>
                    <TitleAndDescription
                      name={item?.partner?.name || ""}
                      address={item?.address || ""}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </div>
  );
}
function Rating({ rating }) {
  if (rating === 0) {
    return <div />;
  }
  return (
    <div
      style={{
        backgroundColor: "green",
        height: "min-content",
        padding: 5,
        borderRadius: 4,
        color: "white",
        position: "absolute",
        top: 5,
        right: 5,
        zIndex: 2,
      }}
    >
      <i className="fa fa-star" /> <span>{rating}</span>
    </div>
  );
}
function TitleAndDescription({ name, address }) {
  return (
    <>
      <p
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          fontSize: "17px",
          marginBottom: 5,
        }}
        className="font-700"
      >
        {name || ""}
        {/* adsfas */}
      </p>
      <p
        style={{
          opacity: "0.7",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          fontSize: "15px",
        }}
        className=""
      >
        {address || ""}
        {/* asd/ */}
      </p>
    </>
  );
}
