import React, { CSSProperties, useState } from "react";
import MainBackgroundImage from "../Components/MainBackgroundImage";
import BannerTitle from "../Components/BannerTitle";
import ButtonCta from "../Components/ButtonCta";
import Tabs from "../Components/Tabs";
import SupportTypes from "./SupportTypes";
import "./style.scss"

interface IProps {}
const ContactUs = (props: IProps) => {
  const contentStyle: React.CSSProperties = {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  };
  const [tabItems] = useState(["Business App Support", "Booking App Support"]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const title1Style: CSSProperties = {
    fontWeight: "normal",
    // opacity: 0.7,
    fontSize: 18,
  };

  return (
    <div className="cont-mb">
      <MainBackgroundImage image={"/media/industry/banner/ovencleaning.jpg"}>
        <div className="cl-section" style={contentStyle}>
          <BannerTitle content="PROFESSIONAL | CUSTOMERS" style={title1Style} />
          <BannerTitle content="Contact Team REACH" />
          <ButtonCta label="Visit help center" />
        </div>
      </MainBackgroundImage>
      <Tabs
        items={tabItems}
        activeIndex={activeTabIndex}
        setIndex={(idx) => setActiveTabIndex(idx)}
      >
        <SupportTypes />
      </Tabs>
    </div>
  );
};

export default ContactUs;
