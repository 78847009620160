import axiosInstance from "./api";
import firebase from "../Firebase";
import { handleFirebaseInfo } from "../Util";
import { appConfiguration } from "../Utils/constants";
import { REACHAUTH_SUCCESS } from "../Actions/types";

var fetchingNewToken = false;
var failedActions: any[] = [];
const getFirebaseUserInfo = async (store, action) => {
  try {
    const fire_user = await firebase.auth().currentUser;
    console.log("fire_user", fire_user);
    const tokenDetails = await fire_user.getIdTokenResult();
    const { authInfo } = handleFirebaseInfo({ tokenDetails, fire_user });
    const auth_req_params = {
      ...authInfo,
      ...appConfiguration,
    };
    const response: any = await axiosInstance.post(
      "user/auth/guest",
      auth_req_params
    );
    if (response.fail) {
      return;
    }
    store.dispatch({ type: REACHAUTH_SUCCESS, payload: response.info });

    handleFailedActions(store);
    fetchingNewToken = false;
  } catch (err) {
    console.log(err);
    store.dispatch({ type: `${action.type}_FAILURE` });
    fetchingNewToken = false;
  }
};
const handleFailedActions = (store) => {
  function exeAction(action) {
    if (action) {
      store.dispatch(action);
      exeAction(failedActions.pop());
    }
  }
  exeAction(failedActions.pop());
};

export const apiMiddleWare = (store) => (next) => async (action) => {
  next(action);

  if (action.isFirebaseCall) {
    getFirebaseUserInfo(store, action);
    return;
  }

  if (!action.isApi) return;
  const { type, payload }: any = action;
  const { url, method, data, extraParams, callback } = payload;
  try {
    const axiosPayload = {
      url,
      method,
      data,
    };
    if (method === "GET") {
      delete axiosPayload.data;
    }
    const response: any = await axiosInstance(axiosPayload);
    if (response.fail) {
      store.dispatch({ type: `${type}_FAILURE`, payload: response });
      callback && callback(response);
    } else {
      const payload = response.info;
      if (extraParams) {
        payload.extraParams = extraParams;
      }
      store.dispatch({ type: `${type}_SUCCESS`, payload });
      callback && callback(payload);
    }
  } catch (err) {
    const { response = {} } = err;
    if (response.status && response.status === 401) {
      failedActions.push({ ...action });
      if (!fetchingNewToken) {
        fetchingNewToken = true;
      }
    } else {
      store.dispatch({ type: `${type}_FAILURE`, payload: response.data });
      callback && callback({ fail: true });
    }
  }
};
