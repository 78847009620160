import React, { useEffect } from "react";
import FindPartners from "./Components/FindPartners";
import TopCategory from "./Components/TopCategories";
import Hire from "./Components/Hire";
import Featured from "./Components/Featured";
import Testimonial from "./Components/Testimonial";
import JoinPartners from "./Components/JoinPartner";
import AppConnections from "../../Components/Appconnections";
import { connect } from "react-redux";
import "./style.scss";
import AppContext from "../../Context";
import { getFeaturedPartners, getTopCategories } from "../../Actions";
import { IHomeReducer } from "../../Models";

interface IProps {
  getTopCategories: () => void;
  getFeaturedPartners: () => void;
  home: IHomeReducer;
}
function Client(props: IProps) {
  const popularIndustry = props.home.topCategories.data || [];
  const featuredPartners = props.home.featuredPartners.data || [];
  const contextStore = React.useContext(AppContext);

  useEffect(() => {
    props.getTopCategories();
    props.getFeaturedPartners();
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      const elements = document.getElementsByClassName("popup-component");
      !elements.length && handleInitialPopup("client-offer");
    }, 10 * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  function handleInitialPopup(type) {
    const data = {
      popup: {
        type,
        errorText: "",
      },
    };
    contextStore.updateData(data);
  }
  return (
    <div>
      <FindPartners />
      <Hire hireMetaData={hireMetaData} />
      <TopCategory popularIndustry={popularIndustry} />

      <Featured featuredPartners={featuredPartners} />
      <JoinPartners />
      <Testimonial />
      <AppConnections
        isClient={true}
        imgPath={"./media/booking-app-mockup.png"}
        title="Get the REACH Booking App"
        description="Instantly book your next appointment with the REACH Booking app and
        discover top-rated local service providers near you"
        androidPath="App will be available to download from 15th July 2022, please check back!"
        iosPath="App will be available to download from 15th July 2022, please check back!"
      />
    </div>
  );
}
const mapStateToProps = (store: any) => {
  return {
    home: store.home,
  };
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    getTopCategories: () => dispatch(getTopCategories()),
    getFeaturedPartners: () => dispatch(getFeaturedPartners()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Client);

const hireMetaData = [
  {
    img: "/media/search.jpg",
    title: "Search",
    content:
      "Looking for a service? We'll find you local, reliable, and vetted businesses that can meet your high standards.",
  },
  {
    img: "/media/feature/getnew.webp",
    title: "Browse",
    content:
      "Select a business by browsing profiles, services with clear pricing, work photos, reviews, insurance and more",
  },
  {
    img: "/media/feature/booking.webp",
    title: "Book",
    content:
      "Choose your required service from the business catalog, schedule a date and time and instantly book an appointment.",
  },
  {
    img: "/media/deliver.jpg",
    title: "Deliver",
    content:
      "The business will provide the requested service either at your home or the location of your choice.",
  },
  {
    img: "/media/review.jpg",
    title: "Review",
    content:
      "After service delivery, leave a review to assist other selecting this excellent service business.",
  },
];
