import { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useHistory } from "react-router-dom";
import "./Benefit.scss";

export default function Benefit(props) {
  const [activeId, setActiveId] = useState("");

  useEffect(() => {
    if (props.data.length) {
      setActiveId(props.data[0].id);
    }
  }, [props.data.length]);
  const history = useHistory();

  const handleClick = (id) => {
    if (id === activeId) {
      setActiveId("");
    } else {
      setActiveId(id);

      setTimeout(() => {
        const position =
          document.getElementById(`feature-item-accordian-${id}`).offsetTop -
          65;
        window.scrollTo({ top: position, behavior: "smooth" });
      }, 10);
    }
  };

  const renderWebElement = () => {
    const activeItem = props.data.find(({ id }) => id === activeId);
    return (
      <div className="home-features-type1">
        <div className="features-list">
          {props.data.map((item) => (
            <div
              className={`feature-item pointer ${
                item.id === activeId ? "active" : ""
              }`}
              key={item.id}
              onClick={() => setActiveId(item.id)}
            >
              <LazyLoadImage
                src={item.icon}
                alt="reach feature"
                effect="blur"
              />
              <span key={item.id} className={`label`}>
                {item.name}
              </span>
            </div>
          ))}
        </div>
        <div className="feature-right-elem">
          <div className="description">
            {activeItem?.smallDescription || ""}
          </div>
          <div
            className="learn-more"
            onClick={() => {
              if (activeItem.url) {
                history.push(activeItem.url + "?bullet=true");
              }
            }}
          >
            Learn more
          </div>

          {activeItem?.image && (
            <div className="feature-image">
              <LazyLoadImage
                src={activeItem?.image || ""}
                effect="blur"
                alt="reach feature"
              />
            </div>
          )}
        </div>
      </div>
    );
  };
  const renderMobileElement = () => (
    <div className="home-features-type2">
      <div className="features-list">
        {props.data.map((item) => (
          <div
            className={`feature-item pointer ${
              item.id === activeId ? "active" : ""
            }`}
            key={item.id}
          >
            <div
              className="feature-item-accordian"
              onClick={() => handleClick(item.id)}
              id={`feature-item-accordian-${item.id}`}
            >
              <LazyLoadImage
                src={item.icon}
                alt="reach feature"
                effect="blur"
              />
              <span key={item.id} className={`label`}>
                {item.name}
              </span>
            </div>
            <div className="feature-image">
              <LazyLoadImage
                src={item.image}
                effect="blur"
                alt="reach feature"
              />
              <div className="description">{item.smallDescription}</div>
              <div
                className="learn-more"
                onClick={() => history.push(item.url + "?bullet=true")}
              >
                Learn more
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* {activeImage && (
        <div className="feature-image">
          <LazyLoadImage src={activeImage} effect="blur" alt="reach feature" />
        </div>
      )} */}
    </div>
  );
  return (
    <div className="home-features cl-section cont-mb">
      <div className="heading">
        <h1 className="main">
          Features built for your business success
        </h1>
        <div>
          <p className="subheader">
            Our range of business features to keep you going above and beyond.
          </p>
        </div>
      </div>
      {renderWebElement()}
      {renderMobileElement()}
    </div>
  );
}
