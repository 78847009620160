import { TextField } from "@material-ui/core";
import React from "react";

interface IInputProps {
  label: string;
  onChange: (val: any) => void;
  placeholder?: string;
  value: string;
  error?: boolean;
  errorHelperText?: string;
}
export default function TextInputUI(props: IInputProps) {
  const {
    error = false,
    errorHelperText = "Invalid input",
    value,
    label = "",
    onChange,
    placeholder = "",
  } = props;
  return (
    <TextField
      variant="filled"
      label={label}
      className={`popup-input ${error ? "error" : ""} reach-text-input`}
      error={error}
      helperText={error && errorHelperText}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder === "" ? "Enter " + label : placeholder}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
}
