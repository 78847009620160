import React from "react";
interface IProps {
  className?: string;
  onClick?: () => void;
  type?: "type1" | "type2";
  label: string;
  customStyles?: React.CSSProperties;
  expanded?: boolean;
}
export default function ButtonCta(props: IProps) {
  const {
    className = "",
    onClick = () => {},
    type = "type1",
    label = "",
    customStyles = {},
    expanded = false,
  } = props;
  return (
    <>
      <button
        className={className}
        style={{
          border: "none",
          borderRadius: "4px",
          fontSize: "clamp(14px, 1.8vw, 16px)",
          fontWeight: 500,
          width: expanded ? "100%" : "max-content",
          padding: "10px 25px",
          backgroundColor: type === "type1" ? "#007fff" : "#efefef",
          color: type === "type1" ? "white" : "#242f2c",
          ...customStyles,
        }}
        onClick={onClick}
      >
        {label}
      </button>
    </>
  );
}
