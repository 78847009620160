import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import rootReducer from "./Reducers/index";
import { apiMiddleWare } from '../Helper/apiMiddleware';

const middleware = [apiMiddleWare];
const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware));

export const store = createStore(rootReducer, composedEnhancers);
export type AppDispatchType = typeof store.dispatch;
export type AppStoreType = typeof store;
