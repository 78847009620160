import React, { useState } from "react";
import MuiPhoneNumber from "material-ui-phone-number";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import Recaptcha from "./Recaptcha";
import ButtonUI from "../../UIElements/ButtonUI";
import TextInputUI from "../../UIElements/TextInputUI";

const defaultValues = {
  name: "",
  businessEmail: "",
  phone: "",
  preferred: "",
};
interface IProps {
  action: (data: any) => void;
  errorText: string;
  isLoading: boolean;
}
const CallSupportPopup = (props: IProps) => {
  const [fieldValues, setFieldValues] = useState(defaultValues);
  const [verifyRecaptcha, setVerifyRecaptcha] = useState(false);
  const validate = () => {
    const validateFlag =
      fieldValues.name !== "" &&
      validateEmail(fieldValues.businessEmail) &&
      fieldValues.preferred !== "" &&
      fieldValues.phone.length >= 15 &&
      verifyRecaptcha;
    return validateFlag;
  };
  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const submitForm = () => {
    const data = {
      form: 4,
      name: fieldValues.name,
      business: fieldValues.businessEmail,
      phone: fieldValues.phone,
      email: fieldValues.businessEmail,
      postal: "EC1V 2NX",
      customer: false,
      industry: "industry",
      message: "Want to join the network",
      demo: false,
      callback: false,
      preferred: fieldValues.preferred,
      referrerEmail: "referrerEmail",
      referrerName: "referrerName",
    };
    props.action({ type: "submit-register-interest", data });
  };

  const handleFields = (name, value) => {
    const cloneFieldValues = { ...fieldValues };
    if (name === "phone") {
      cloneFieldValues["phone"] = value;
      setFieldValues(cloneFieldValues);
    } else {
      cloneFieldValues[name] = value;
      setFieldValues(cloneFieldValues);
    }
  };
  return (
    <>
      <p className={"form-title"}>Request a Callback</p>
      <p className={"form-subtitle"}>
        Please fill the below form, one of our friendly Support team member will
        call you back as per your preference, to understand how can we help you
        grow your business.
      </p>
      <TextInputUI
        label="Full Name *"
        value={fieldValues.name}
        onChange={(val) => handleFields("name", val)}
        placeholder="First name & last name"
      />
      <TextInputUI
        label="Business Email *"
        value={fieldValues.businessEmail}
        onChange={(val) => handleFields("businessEmail", val)}
        placeholder="business_email@example.com"
      />
      <MuiPhoneNumber
        label="Phone number"
        defaultCountry={"gb"}
        onChange={(val) => handleFields("phone", val)}
        className={"popup-input phone"}
        name="phone"
        value={fieldValues.phone}
        onlyCountries={["gb"]}
        countryCodeEditable={false}
      />
      <FormControl variant="filled" className="popup-input">
        <InputLabel id="demo-simple-select-filled-label">
          Preferred Time
        </InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          name="preferred"
          value={fieldValues.preferred}
          onChange={(e) => handleFields("preferred", e.target.value)}
          renderValue={
            fieldValues.preferred !== "" ? undefined : () => <div>Answer</div>
          }
        >
          <MenuItem value={"any"}>Any Time</MenuItem>
          <MenuItem value={"8AM-12PM"}>Between 8 AM - 12 PM</MenuItem>
          <MenuItem value={"12PM-2PM"}>Between 12 PM - 2 PM</MenuItem>
          <MenuItem value={"2PM-6PM"}>Between 2 PM - 6 PM</MenuItem>
          <MenuItem value={"6PM-8PM"}>Between 6 PM - 8 PM</MenuItem>
        </Select>
      </FormControl>
      {props.errorText && props.errorText !== "" && (
        <div
          style={{
            color: "red",
            marginBottom: "10px",
            fontSize: "14px",
          }}
        >
          {props.errorText}!
        </div>
      )}
      <Recaptcha hanldeRecaptcha={(flag) => setVerifyRecaptcha(flag)} />
      <ButtonUI
        onPress={() => submitForm()}
        disabled={!validate()}
        label={"Request a Call"}
        loading={props.isLoading}
      />
    </>
  );
};

export default CallSupportPopup;
