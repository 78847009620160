import React from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { clientThemeColor, handleWindowUrl } from "../../../Util";

export default function JoinPartner() {
  const history = useHistory();

  return (
    <section className="cl-section section-block join-partner">
      <div className="join-partner-content">
        <img alt={"reach-partners"} src={"./media/partner-group.png"} />
        <div className="right-cont">
          <p className="title">
            Are you a business or professional ?<br />
            Get new customers in your area.
          </p>
          <p className="subtitle">
            Grow your business by joining the fastest growing Marketplace in
            London. Let your local customers know the services you offer and
            book you instantly.
          </p>
          <Button
            className=""
            variant="contained"
            color="primary"
            style={{
              backgroundColor: clientThemeColor,
              borderRadius: "4px",
              height: 56,
              maxWidth: 320,
              width: "100%",
            }}
            onClick={() => {
              history.push("/");
            }}
          >
            <div
              style={{ fontWeight: 700 }}
              onClick={() => handleWindowUrl("/business")}
            >
              List your business
            </div>
          </Button>
        </div>
      </div>
    </section>
  );
}
