import environment from "../../../environment";
import "./BottomBar.scss";
const BottomBar = function () {
  return (
    <div className="bottomBar">
      <button
        onClick={() => window.open(`${environment.PARTNER_ENDPOINT}/signup`)}
      >
        Sign Up free
      </button>
      <div className="note">Join the REACH Network</div>
    </div>
  );
};

export default BottomBar;
