import React, { useEffect } from "react";
/* import Header from "../Components/Header";
import environment from "../environment"; */
import "./style.scss";

var __html = require("./Components/PrivacyPolicy.html");

function PrivaryPolicy() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <div className={"terms-of-service-container"}>
        <div dangerouslySetInnerHTML={{ __html }} id={"exported_html"} />
      </div>
    </>
  );
}
export default PrivaryPolicy;
