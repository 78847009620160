import React, { CSSProperties } from "react";

interface IProps {
  children: React.ReactNode;
  items: Array<string>;
  activeIndex: number;
  setIndex: (idx: number) => void;
}
export default function Tabs(props: IProps) {
  const containerStyle: CSSProperties = {};
  const itemsStyle: CSSProperties = {
    padding: "0px 0px",
    marginBottom: 40,
  };
  const itemStyle = (idx): CSSProperties => {
    return {
      display: "inline-block",
      padding: "5px 0px",
      fontSize: "clamp(14px, 3vw, 24px)",
      fontWeight: 500,
      borderBottom: props.activeIndex === idx ? "4px solid #007bff" : "none",
      marginRight: "clamp(20px, 4vw, 40px)",
      cursor:"pointer"
    };
  };

  return (
    <div style={containerStyle} className="cl-section">
      <ul style={itemsStyle}>
        {props.items.map((item, idx) => (
          <li
            key={idx}
            style={itemStyle(idx)}
            onClick={() => props.setIndex(idx)}
          >
            {item}
          </li>
        ))}
      </ul>
      {props.children}
    </div>
  );
}
