export const FIREBASEINFO = "FIREBASEINFO";
export const FIREBASEINFO_FAILURE = "FIREBASEINFO_FAILURE";
export const REACHAUTH = "REACHAUTH";
export const REACHAUTH_SUCCESS = "REACHAUTH_SUCCESS";
export const REACHAUTH_FAILURE = "REACHAUTH_FAILURE";
export const SIGNIN = "SIGNIN";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";
export const TOPCATEGORIES = "TOPCATEGORIES";
export const TOPCATEGORIES_SUCCESS = "TOPCATEGORIES_SUCCESS";
export const TOPCATEGORIES_FAILURE = "TOPCATEGORIES_FAILURE";
export const FEATUREDPARTNERS = "FEATUREDPARTNERS";
export const FEATUREDPARTNERS_SUCCESS = "FEATUREDPARTNERS_SUCCESS";
export const FEATUREDPARTNERS_FAILURE = "FEATUREDPARTNERS_FAILURE";
export const SENDSMSLINK = "SENDSMSLINK";