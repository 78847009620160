import React, { useEffect, useState } from "react";
import Benefit from "./Components/Benefit";
import FAQComponent from "./Components/FAQ/FAQComponent";
import Gallery from "./Components/Carousel/Carousel";
import Topbar from "./Components/Topbar/Topbar";
import SignupCatalog from "./Components/SignupCatalog/SignupCatalog";
import { useLocation, useHistory } from "react-router-dom";
import { featuresWithIndustryImages, industryRelatedBanner } from "../Util";
import AppContext from "../Context";
import FAQData from "../Data/FaqHome.json";
import Appconnections from "../Components/Appconnections";
function HomeContainer() {
  const contextStore = React.useContext(AppContext);

  const [features, setFeatures] = useState([]);
  const [bannerImage, setBannerImage] = useState("");
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let timer = setTimeout(() => {
      handleInitialPath();
    }, 200);
    return () => {
      clearTimeout(timer);
    };
  }, [location.pathname, location.search]);
  function handleInitialPath() {
    var search = location.search.substring(1);
    if (!search) {
      return;
    }
    const res = JSON.parse(
      '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
    let topSpace = 80;
    const type = res.type;
    if (type) {
      let target = "";
      if (type === "feature") {
        target = "feature-catalog";
      } else if (type === "pricing") {
        target = "pricing-component";
      } else if (type === "footer") {
        target = "footer";
      } else if (type === "industries") {
        target = "carousel-component";
      }
      var scrollDiv = document!.getElementById(target)!.offsetTop - topSpace;
      window.scrollTo({ top: scrollDiv, behavior: "smooth" });
    }
  }
  function handleInitialPopup(type) {
    const data = {
      popup: {
        type,
        errorText: "",
      },
    };
    contextStore.updateData(data);
  }
  useEffect(() => {
    let timer = setTimeout(() => {
      const elements = document.getElementsByClassName("popup-component");
      !elements.length && handleInitialPopup("offer-register");
    }, 10000);
    return () => {
      clearTimeout(timer);
    };
    // return () => window.removeEventListener("scroll", adjustFloater);
  }, []);
  useEffect(() => {
    getPersonalizedIndustry();
  }, []);
  function getPersonalizedIndustry() {
    setFeatures(featuresWithIndustryImages());
    setBannerImage(industryRelatedBanner());
  }

  return (
    <div id="home-container">
      <div
        className={`App home-container ${
          contextStore?.data?.popup?.type !== "" ? "modal-open" : ""
        }`}
      >
        <div id="topbar-wrapper">
          <Topbar
            action={() => handleInitialPopup("register-interest")}
            image={bannerImage}
          />
        </div>
        <Benefit data={features} />
        <Gallery
          action={(data) => {
            if (data.id) {
              localStorage.setItem("personalizedIndustry", data.url);
              history.push(data.url);
            }
          }}
        />
        <SignupCatalog />
        <Appconnections
          imgPath={"./media/business-app-mockup.png"}
          title="Get the REACH Business App"
          description="Manage your business on the go with our mobile apps, so you can work from anywhere at anytime"
          androidPath="https://play.google.com/store/apps/details?id=biz.reach.partner"
          iosPath="App will be available to download from 12th December 2021, please check back!"
        />
        <FAQComponent data={FAQData} />
      </div>
    </div>
  );
}

export default HomeContainer;
