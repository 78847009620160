import { produce } from "immer";
import {
  FEATUREDPARTNERS,
  FEATUREDPARTNERS_SUCCESS,
  FIREBASEINFO_FAILURE,
  REACHAUTH_SUCCESS,
  TOPCATEGORIES,
  TOPCATEGORIES_SUCCESS,
} from "../../Actions/types";
import { IHomeReducer, IReducerActionInterface } from "../../Models";

const initialState: IHomeReducer = {
  isLoggedIn: false,
  topCategories: {
    isLoading: false,
    data: [],
  },
  featuredPartners: {
    isLoading: false,
    data: [],
  },
};

const home = (state = initialState, action: IReducerActionInterface) => {
  switch (action.type) {
    case REACHAUTH_SUCCESS:
      const { sessionToken, tokenType } = action.payload;
      localStorage.setItem(
        "sessionDetail",
        JSON.stringify({ token: sessionToken, type: tokenType })
      );
      return produce(state, (draft) => {
        draft.isLoggedIn = true;
      });
    case FIREBASEINFO_FAILURE:
      return produce(state, (draft) => {
        draft.isLoggedIn = true;
      });
    case TOPCATEGORIES:
      return produce(state, (draft) => {
        draft.topCategories = { isLoading: true, data: [] };
      });
    case FEATUREDPARTNERS:
      return produce(state, (draft) => {
        draft.featuredPartners = { isLoading: true, data: [] };
      });
    case TOPCATEGORIES_SUCCESS:
      return produce(state, (draft) => {
        draft.topCategories = { isLoading: false, data: action.payload.result };
      });
    case FEATUREDPARTNERS_SUCCESS:
      return produce(state, (draft) => {
        draft.featuredPartners = {
          isLoading: false,
          data: action.payload.featured,
        };
      });
    default:
      return state;
  }
};

export default home;
