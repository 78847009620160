import React, { CSSProperties } from "react";
import MuiPhoneNumber from "material-ui-phone-number";

interface IProps {
  onChange: (value) => void;
  value: string;
  label: string;
}
export default function PhoneNumberTextInput(props: IProps) {
  const containerStyle: CSSProperties = {};

  return (
    <>
      <style>{`
      .phone-number-text-input label{
          font-size: 16px;
      }
      .phone-number-text-input input{
          font-size: 14px;
          padding: 15px 0px;
      }
      .phone-number-text-input > div{
        background-color: white;
        border: 1px solid rgba(36, 39, 44, 0.15);
        border-radius: 5px;
        outline: 0;
      }
      .phone-number-text-input > div::before{
        border-bottom: none;
      }
      .phone-number-text-input > div::focus{
        outline: 0;
      }
      `}</style>
      <MuiPhoneNumber
        label="Business Phone"
        defaultCountry={"gb"}
        onChange={(val) => props.onChange(val)}
        className={"phone-number-text-input"}
        name="phone"
        value={props.value}
        onlyCountries={["gb"]}
        countryCodeEditable={false}
      />
    </>
  );
}
