import React from "react";
import CarouselComponent from "./Carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { handleWindowUrl } from "../../../Util";
import environment from "../../../environment";
const imageHeight: number = 115;

export default function TopCategory(props) {
  return (
    <div className="cl-section section-block">
      <label>Top categories - Greater London</label>
      <CarouselComponent
        cols={5}
        rows={1}
        responsiveLayout={[
          {
            breakpoint: 992,
            cols: 4,
            rows: 1,
            gap: 10,
          },
          {
            breakpoint: 768,
            cols: 2,
            rows: 3,
            gap: 10,
          },
        ]}
        mobileBreakpoint={1}
        items={props.popularIndustry}
        gap={20}
        arrowPositionTop={Math.ceil(imageHeight / 2)}
        renderItem={(item) => (
          <div
            className="car-item pointer"
            onClick={() => {
              const url = `${environment.CLIENT_ENDPOINT}/search?type=industry&id=${item.id}&name=${item.name}`;
              window.open(url, "_self");
            }}
            style={{ padding: "0px" }}
          >
            <LazyLoadImage
              width="100%"
              height={imageHeight}
              src={item.image[0] || "/media/coverimage.png"}
              style={{ minHeight: imageHeight }}
              alt={item?.name || "reach-top-categories"}
              effect="blur"
            />
            <p
              style={{ paddingBlock: 12, fontSize: "clamp(12px,3.5vw,16px)" }}
              className="text-center font-700"
            >
              {item.name}
            </p>
          </div>
        )}
      />
    </div>
  );
}
