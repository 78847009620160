import React from "react";
import "./Carousel.scss";
import Carousel from "react-grid-carousel";
import CarouselData from "../../../Data/IndustryData.json";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
let leftArrowImage = document.getElementById("left");
if (leftArrowImage !== null) {
  leftArrowImage.innerHTML =
    '<img src="./media/left-arrow.png" alt="left-arrow">';
}

let rightArrowImage = document.getElementById("right");
if (rightArrowImage !== null) {
  rightArrowImage.innerHTML =
    '<img src="./media/right-arrow.png" alt="right-arrow">';
}
interface GalleryProps {
  action: Function;
}
const Gallery: React.FC<GalleryProps> = (props) => {
  const handleClick = (data) => {
    props.action(data);
  };
  return (
    <div
      className="carousel-component cont-mb cl-section"
      id="carousel-component"
    >
      <div className="heading">
        <h1 className="carousel-header">
          Simple, yet flexible software for every Industry
        </h1>
        <div>
          <p className="carousel-subheader">
            Explore the range of business categories supported from the wide
            range of industries included in the software.
          </p>
        </div>
      </div>
      <Carousel
        cols={4}
        rows={2}
        gap={25}
        loop
        arrowLeft={
          <button id="left" aria-label="arrow-left">
            {leftArrowImage}
          </button>
        }
        arrowRight={
          <button id="right" aria-label="arrow-right">
            {rightArrowImage}
          </button>
        }
        clasName={"carousel"}
        mobileBreakpoint={100}
        responsiveLayout={[
          {
            breakpoint: 992,
            cols: 3,
            rows: 2,
            gap: 10,
          },
        ]}
      >
        {CarouselData.map((item, id) => {
          const { image = "", name = "" } = item || {};
          return (
            <Carousel.Item className="grid" key={id}>
              <div
                className="image-container pointer"
                onClick={() => handleClick(item)}
              >
                <LazyLoadImage
                  className="carousel-img"
                  width="100%"
                  effect="blur"
                  alt={name}
                  src={image}
                  onClick={() => props.action}
                />
                <p className="layover-content">{name}</p>
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Gallery;
