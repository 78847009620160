import React, { CSSProperties } from "react";
import AppContext from "../Context";
import TextInputUI from "../UIElements/TextInputUI";
import { defaultRequestForm } from "../Utils/constants";
import axios from "axios";
import environment from "../environment";
import ButtonUI from "../UIElements/ButtonUI";
import PhoneNumberTextInput from "../Components/PhoneNumberTextInput";

interface IProps {
  style?: CSSProperties;
}
const defaultValues = {
  first: "",
  last: "",
  fullName: "",
  businessEmail: "",
  businessName: "",
  postCode: "",
  phone: "",
  industry: "",
  subject: "",
  description: "",
};
const sectionStyle = {
  width: "33.33%",
  "@media (max-width: 767px)": {
    width: "50%",
  },
};
export default function SupportTypes(props: IProps) {
  const containerStyle = {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 767px)":{
      flexDirection: "column"
    }
  };

  return (
    <div style={{ border: "2px solid #d8d6d6", padding: 40, borderRadius: 4 }}>
      <div style={containerStyle} className="contact-us-supports">
        <EmailSupport />
        <CallSupport />
        <ChatSupport />
      </div>
      <div>
        <EmailSupportForm />
      </div>
    </div>
  );
}
const contentStyle: CSSProperties = {
  fontSize: 12,
  color: "#007bff",
  fontWeight: 500,
};
function EmailSupportForm() {
  const [fieldValues, setFieldValues] = React.useState(defaultValues);
  const [isLoading, setIsLoading] = React.useState(false);
  const contextStore = React.useContext(AppContext);

  const validate = () => {
    const validateFlag =
      fieldValues.first !== "" &&
      fieldValues.last !== "" &&
      fieldValues.phone.length >= 15 &&
      validateEmail(fieldValues.businessEmail);

    return validateFlag;
  };
  const validateEmail = (email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleForm = () => {
    const data = defaultRequestForm;
    data.name = fieldValues.first + "" + fieldValues.last;
    data.message = fieldValues.description;
    data.email = fieldValues.businessEmail;
    data.phone = fieldValues.phone;
    submitForm({ type: "", data });
  };
  const submitForm = (payload) => {
    const newStoreData = contextStore.data;
    newStoreData.popup.errorText = "";
    contextStore.updateData(newStoreData);
    setIsLoading(true);
    axios({
      url: `${environment.API_ENDPOINT}/website/enquiry`,

      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: " application/vnd.aurlis.reach+json; version=1.0",
      },
      data: JSON.stringify(payload.data),
    })
      .then((response) => {
        setFieldValues(defaultValues);
        setIsLoading(false);
        newStoreData.popup.type = "thank-you";
        contextStore.updateData(newStoreData);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error.response);
        let errorText = "Some error occured";
        if (error.response?.data?.mesg) {
          let temp = error.response?.data?.mesg.split(" ");
          temp.splice(0, 1);
          errorText = temp.join(" ");
        }
        newStoreData.popup.errorText = errorText;
      });
  };
  const handleFields = (name, value) => {
    const cloneFieldValues = { ...fieldValues };
    if (name === "phone") {
      cloneFieldValues["phone"] = value;
      setFieldValues(cloneFieldValues);
    } else {
      cloneFieldValues[name] = value;
      setFieldValues(cloneFieldValues);
    }
  };
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          maxWidth: 500,
          margin: "auto",
          gap: 15,
          display: "flex",
          flexDirection: "column",
          paddingTop: "50px",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
          <TextInputUI
            label="First name"
            value={fieldValues.first}
            placeholder={"Enter your first name"}
            onChange={(val) => handleFields("first", val)}
          />
          <TextInputUI
            label="Last name"
            value={fieldValues.last}
            placeholder={"Enter your last name"}
            onChange={(val) => handleFields("last", val)}
          />
        </div>
        <PhoneNumberTextInput
          label="Business Phone"
          onChange={(val) => handleFields("phone", val)}
          value={fieldValues.phone}
        />
        <TextInputUI
          label="Your email address"
          value={fieldValues.businessEmail}
          placeholder={"youremail@example.com"}
          onChange={(val) => handleFields("businessEmail", val)}
        />
        <ButtonUI
          onPress={() => handleForm()}
          disabled={!validate()}
          label={"Submit"}
          loading={isLoading}
        />
      </div>
    </div>
  );
}

function EmailSupport() {
  return (
    <SupportItem
      icon="fa-envelope"
      title="Send us your query via form"
      style={sectionStyle}
    >
      <>
        <p style={contentStyle}>
          For all enquiries, please email us using the form below
        </p>
        <p style={contentStyle}>
          We aim to reply your queries within{" "}
          <span>1-2 business working days</span>
        </p>
      </>
    </SupportItem>
  );
}
function CallSupport() {
  return (
    <SupportItem
      icon="fa-phone"
      title="Call us directly for support"
      style={sectionStyle}
    >
      <>
        <p style={contentStyle}>Mon - Sat: 9am - 7pm</p>
        <p style={contentStyle}>Sun: Line closed</p>
      </>
    </SupportItem>
  );
}
function ChatSupport() {
  return (
    <SupportItem
      icon="fa-comment"
      title="Chat us directly for support"
      style={sectionStyle}
    >
      <>
        <p style={contentStyle}>
          Send us a direct message via the social media channels
        </p>
      </>
    </SupportItem>
  );
}
interface ISupportItemProps {
  icon: string;
  title: string;
  children: React.ReactNode;
  style?: CSSProperties;
}
function SupportItem(props: ISupportItemProps) {
  const iconStyle: CSSProperties = {
    fontSize: 70,
    width: "100%",
    color: "#007bff",
    margin: "10px 0px",
  };
  const titleStyle: CSSProperties = {
    fontWeight: 500,
    textAlign: "center",
  };

  return (
    <div style={{ ...props.style }}>
      <p style={titleStyle}>{props.title}</p>
      <i className={`fa ${props.icon}`} style={iconStyle}></i>
      <div style={{ textAlign: "center" }}>{props.children}</div>
    </div>
  );
}
