import React, { CSSProperties } from "react";

interface IProps {
  content: string;
  style?: CSSProperties;
}
export default function BannerTitle(props: IProps) {
  const containerStyle: CSSProperties = {
    fontSize: 25,
    fontWeight: 500,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    width: "fit-content",
    padding: "0 10px",
    marginBottom: 10,
    ...props.style,
  };

  return <div style={containerStyle}>{props.content}</div>;
}
