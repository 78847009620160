import React, { useState } from "react";
interface FAQSetProps {
  data: { question: String; answer: String }[];
}
const FaqSetComponent: React.FC<FAQSetProps> = (props) => {
  const [activeQuestion, setActiveQuestion] = useState<Number | null>(null);
  const handleClick = (index: Number) => {
    if (index === activeQuestion) {
      setActiveQuestion(null);
      return;
    }
    setActiveQuestion(index);
  };
  return (
    <>
      {props.data.map((question, idx) => (
        <div
          className={`faq-question-set ${
            idx === activeQuestion ? "active" : ""
          }`}
          key={idx}
        >
          <p
            className={"faq-question pointer"}
            onClick={() => handleClick(idx)}
          >
            {question.question}
          </p>
          <p className={"faq-answer"}>{question.answer}</p>
        </div>
      ))}
    </>
  );
};
export default FaqSetComponent;
