import FeatureItem from "./FeatureItem";
import SignupCatalog from "../../+Home/Components/SignupCatalog/SignupCatalog";
import Banner from "../../Components/Banner";
import { featuresWithIndustryImages } from "../../Util";
import "./style.scss";

export default function FeatureComponent() {
  const FeatureData = featuresWithIndustryImages();
  const list1 = FeatureData.slice(0, 2);
  const list2 = FeatureData.slice(2);
  const bannerData = {
    image: "/media/feature/banner/banner.webp",
    name: "REACH BUSINESS APP",
    brief: "GROW YOUR BUSINESS WITH OUR DIGITAL TOOLS",
  };
  return (
    <div className="feature-main">
      <Banner data={bannerData} />
      <div className="title cl-section cont-mb text-center"></div>
      <div className="cl-section">
        {list1.map((item, idx) => (
          <FeatureItem
            key={idx}
            data={item}
            extraClass={`pickup-component${idx}`}
          />
        ))}
      </div>
      <SignupCatalog />
      <div className="cl-section ">
        {list2.map((item, idx) => (
          <FeatureItem
            key={idx}
            data={item}
            extraClass={`pickup-component${idx}`}
          />
        ))}
      </div>
    </div>
  );
}
