import Footer from "../../Components/Footer";
import SignupCatalog from "../../+Home/Components/SignupCatalog/SignupCatalog";
import Plans from "./Plans";
import Banner from "../../Components/Banner";
import FaqSetComponent from "../../+Home/Components/FAQ/FAQComponent";
import FAQData from "../../Data/FaqPricing.json";
import "./style.scss";

export default function FeatureComponent() {
  const bannerData = {
    image: "/media/feature/banner/banner.webp",
    name: "FREE | PROFESSIONAL",
    brief: "GET YOUR BUSINESS GROWING WITH OUR AFFORDABLE PLAN",
  };
  return (
    <div className="pricing-main">
      <Banner data={bannerData} />
      {/* <div className="sub-title cont-mb cl-section text-center">
        No credit card required. No commitment, downgrade or cancel your account
        anytime.
      </div> */}
      <Plans />
      <SignupCatalog />
      <FaqSetComponent data={FAQData} />
    </div>
  );
}
