import React from "react";
import "./style.scss";

export default function FeatureItem(props) {
  const { data = {}, extraClass = "" } = props;
  const { name = "", bulletPoints = [], description = "" } = data;
  return (
    <div className={`feature-item cont-mb ${extraClass}`}>
      <div className={"pickup-content"}>
        <p className={"title"}>{name}</p>
        <p className={"description roboto-font"}>{description}</p>
        {bulletPoints.map((option, idx) => (
          <div key={idx} className={"option"}>
            <img src={"/media/check.svg"} alt={"Reach"} />
            <span className="roboto-font">{option}</span>
          </div>
        ))}
      </div>
    </div>
  );
}
