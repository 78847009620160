const environment = {
    API_ENDPOINT: "https://api.reach.biz/api",
    PARTNER_ENDPOINT: "https://business.reach.biz",
    CLIENT_ENDPOINT: "https://booking.reach.biz",
    firebaseConfig: {
        apiKey: "AIzaSyAagFPBRbr-4O4BZDQjeYn7_Ig4KOkkvpc",
        authDomain: "reach-services-network.firebaseapp.com",
        projectId: "reach-services-network",
        storageBucket: "reach-services-network.appspot.com",
        messagingSenderId: "278019913924",
        appId: "1:278019913924:web:bc82499ec0bc9eab9e3191",
        measurementId: "G-T43F8WBT7N"
    },
}
export default environment;