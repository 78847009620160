import React from "react";
import Carousel from "react-grid-carousel";
interface IProps {
  cols: number;
  rows: number;
  gap: number | string;
  responsiveLayout: Array<any>;
  items: Array<any>;
  renderItem: (item: any) => void;
  mobileBreakpoint?: number;
  arrowPositionTop?: number;
}
export default function CarouselComponent(props: IProps) {
  return (
    <Carousel
      cols={props.cols || 4}
      rows={props.rows || 1}
      gap={props.gap || 10}
      hideArrow={props.items.length === 0}
      responsiveLayout={props.responsiveLayout}
      mobileBreakpoint={props.mobileBreakpoint || 767}
      arrowRight={
        <button
          className={`arrow-right`}
          style={{
            top: props.arrowPositionTop
              ? `calc(${props.arrowPositionTop}px - 25px)`
              : "calc(50% - 25px)",
          }}
        >
          <img alt="reach" src="./media/Shape.png" />
        </button>
      }
      arrowLeft={
        <button className={`arrow-left`}>
          <img alt="reach" src="./media/Shape.png" />
        </button>
      }
    >
      {props.items.map((item, idx) => (
        <Carousel.Item key={idx}>{props.renderItem(item)}</Carousel.Item>
      ))}
    </Carousel>
  );
}
