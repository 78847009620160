import { useEffect, useState } from "react";
import FeatureInfoComponent from "./Components";
import Features from "../Data/Features.json";
import SubFeatures from "../Data/SubFeatures.json";
import { useLocation } from "react-router-dom";

export default function FeatureInfo() {
  const location = useLocation();

  const [featureInfoData, setFeatureInfoData] = useState<any>([]);
  const [feature, setFeature] = useState<any>({});
  useEffect(() => {
    const filteredItem = Features.find((d) =>
      d.url.includes(location.pathname)
    );
    if (filteredItem) {
      const applicable_sub_features = SubFeatures.filter(({ id }) =>
        filteredItem.subFeatures.includes(id)
      );
      setFeature(filteredItem);
      setFeatureInfoData(applicable_sub_features);
    }
  }, []);
  return (
    <FeatureInfoComponent subfeatures={featureInfoData} feature={feature} />
  );
}
