import environment from "../../environment";
import "./style.scss";
export default function Banner({ data }) {
  const { name = "", brief = "", image = "" } = data;
  // const modifiedImagePath = image.split(".").join("-small.");
  function handleCall(url) {
    window.open(url);
  }
  return (
    <div className="cont-mb banner-main-wrapper">
      <div className="banner-main cl-section">
        {/* <img
        srcSet="/media/feature/banner/banner-small.webp 480w"
        sizes="(min-width : 480px) 100vw"
        src={image}
        alt={"reach-feature"}
        className="banner-main-hero-image"
      /> */}
        <div className="banner-main-content">
          <p className="banner-main-content-title">{name}</p>
          <p className="banner-main-content-brief">{brief}</p>
          <button
            className="banner-main-content-button pointer cta-btn-type1"
            onClick={() => {
              handleCall(`${environment.PARTNER_ENDPOINT}/signup`);
            }}
          >
            {/* Get Started {`>`} */}
            Join REACH - It's free
          </button>
        </div>
        {/* <picture> */}
        {/* <source srcSet={modifiedImagePath} media="(max-width: 480px)"></source> */}
        <img
          src={image}
          alt={"reach-feature"}
          className="banner-main-hero-image"
        />
        {/* </picture> */}
      </div>
      <img
        src={image}
        alt={"reach-feature"}
        className="banner-main-hero-image-bg"
      />
    </div>
  );
}
