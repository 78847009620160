import React, { useRef, useEffect, useState } from "react";
import { ReactComponent as ReachLogo } from "../SVG/reach-logo.svg";
import { useLocation, useHistory } from "react-router-dom";
import environment from "../environment";
import { isClientRoute } from "../Util";

export default function Header() {
  const [showSideBar, setShowSideBar] = useState(false);
  const [menuItems, setMenuItems] = useState<any>(partnerMenuItems);
  const [headerType, setHeaderType] = useState<string>("type1");
  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    let type = headerType;
    let items = menuItems;
    if (isClientRoute()) {
      items = clientMenuItems;
      type = "type2";
    } else {
      items = partnerMenuItems;
      type = "type1";
    }
    if (type !== headerType) {
      setHeaderType(type);
      setMenuItems(items);
    }
  }, [location]);
  const floatingMenu: any = useRef();
  const history = useHistory();

  const topNavbarActions = (target, isUrl?) => {
    if (showSideBar) {
      setShowSideBar(false);
    }
    let topSpace = 80;
    // if (showSideBar) {
    //   topSpace = 5;
    // }
    if (target.includes("http")) {
      window.open(target);
    } else if (target.includes("/")) {
      history.push(target);
    } else if (target === "industries") {
      // if (location.pathname === "/") {
      //   var scrollDiv1 =
      //     document!.getElementById("carousel-component")!.offsetTop - 80;
      //   window.scrollTo({ top: scrollDiv1, behavior: "smooth" });
      // } else {
      history.push("/business?type=industries");
      // }
    } else {
      var scrollDiv = document.getElementById(target);
      if (scrollDiv) {
        const position = scrollDiv.offsetTop - topSpace;
        window.scrollTo({ top: position, behavior: "smooth" });
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", adjustFloater);
    return () => window.removeEventListener("scroll", adjustFloater);
  }, []);
  const adjustFloater = () => {
    const offerBannerHeight = 0;
    const scrollY = window.scrollY;
    let position = offerBannerHeight - scrollY;
    let className = `header`;

    if (position < 0) {
      position = 0;
      className += " header-inverse";
    }
    document!.getElementById("header")!.style!.top = `${position}px`;
    document!.getElementById("header")!.className = className;
  };
  const navElements = (cls) => {
    return (
      <ul className={cls}>
        <i
          className="fa fa-bars hamburger"
          onClick={() => setShowSideBar(!showSideBar)}
        ></i>
        {menuItems.map((item, idx) => (
          <li
            onClick={() => topNavbarActions(item.param)}
            key={idx}
            className={`${
              location.pathname.includes(item.label.toLowerCase()) ||
              location.search.includes(item.label.toLowerCase())
                ? "active"
                : ""
            }`}
          >
            {item.label}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <div className="header-wrapper">
      <div
        className={`header`}
        id="header"
        ref={floatingMenu}
        data-type={headerType}
      >
        <div className={"header-content cl-section"}>
          <div
            className="h-left"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            <ReachLogo />
            <div className="reach-title pointer">REACH</div>
          </div>
          {navElements("h-main-elements")}
          <div
            className={`header-sidebar ${showSideBar ? "active" : ""}`}
            onClick={() => {
              setShowSideBar(false);
            }}
          >
            <div
              className="header-sidebar-content"
              onClick={(e) => e.stopPropagation()}
            >
              {navElements("h-side-elements")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const partnerMenuItems = [
  {
    label: "For Booking",
    param: "/",
  },
  {
    label: "Features",
    param: "/en-gb/features",
  },
  {
    label: "Industries",
    param: "industries",
  },
  {
    label: "Pricing",
    param: "/en-gb/pricing",
  },
  {
    label: "Contact Us",
    param: "/en-gb/contact-us",
  },
  {
    label: "Blog",
    param: "https://blog.reach.biz",
  },
  {
    label: "Sign in",
    param: `${environment.PARTNER_ENDPOINT}/signin`,
  },
  {
    label: "Sign up free",
    param: `${environment.PARTNER_ENDPOINT}/signup`,
  },
];
const clientMenuItems = [
  {
    label: "For Business",
    param: "/business",
  },
  {
    label: "Contact Us",
    param: "/en-gb/contact-us",
  },
  {
    label: "Blog",
    param: "https://blog.reach.biz",
  },
  {
    label: "Sign in",
    param: `${environment.CLIENT_ENDPOINT}/signin`,
  },
  {
    label: "Register Now",
    param: `${environment.CLIENT_ENDPOINT}/signup`,
  },
];
