import React from "react";
export const AppContextData = {
    data: {
        popup: {
            type: "",
            errorText: "",

        },
    }
}
const AppContext = React.createContext({ ...AppContextData, updateData: (data) => { } });

export const ContextProvider = AppContext.Provider
export const ContextConsumer = AppContext.Consumer
export default AppContext;