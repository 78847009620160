import React from "react";
import { ReactComponent as CheckMark } from "../../SVG/check-mark.svg";
import { getThemeColor, isClientRoute } from "../../Util";

const ThankYou = (props) => {
  const { errorText, action } = props;
  return (
    <>
      {errorText ? (
        <div
          style={{
            color: "red",
            marginTop: "50px",
            marginBottom: "50px",
            textAlign: "center",
          }}
        >
          {errorText}
        </div>
      ) : (
        <div className={`thankyou ${isClientRoute()&& "client"}`}>
          <CheckMark />
          <p className={"title"}>Thank You!</p>
          <p className={"subtitle"}>
            We have now received your request, one of our friendly support team
            member will call you back soon.
          </p>
          <button onClick={() => action({ type: "close" })} style={{backgroundColor: getThemeColor()}}>CLOSE</button>
        </div>
      )}
    </>
  );
};
export default ThankYou;
