import React from "react";
import QuestionSet from "./FAQSet";
import "./FAQ.scss";

const Footer = (props) => {
  const { data } = props;
  return (
    <div className="faq-component cont-mb cl-section">
      <p className={"faq-title"}>Frequently asked questions</p>
      <p className={"faq-subtitle"}>
        Maximize sales, manage schedules and engage your clients.{" "}
      </p>
      <QuestionSet data={data} />
    </div>
  );
};

export default Footer;
