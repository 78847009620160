import firebase from "firebase";
import environment from "../environment";


if (!firebase.apps.length) {
    firebase.initializeApp(environment.firebaseConfig);
}
const auth = firebase.auth();
export { auth };
export default firebase;
