export const appConfiguration = {
    appId: 3,
    appVersion: "0.51",
    appPlatform: 1,
}
export const formTypes = {
    offerRegister: 1,
    registerInterest: 2,
    referBusiness: 3,
    email: 5,
    clientSmsLink: 6,
    partnerSmsLink: 7
}
export const defaultRequestForm = {
    form: 5,
    name: "name",
    business: "business",
    phone: "phone",
    email: "email@test.com",
    postal: "EC1V 2NX",
    customer: false,
    industry: "industry",
    message: "message",
    demo: false,
    callback: false,
    preferred: "preferred",
    referrerEmail: "referrerEmail",
    referrerName: "referrerName"
}